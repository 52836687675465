import { Box } from 'grommet'
import { FormView, Hide } from 'grommet-icons'
import * as React from 'react'
import ErrorString from '../../../../atoms/ErrorString/ErrorString'
import { getLogoUrl } from '../../../../common/AssetsHelper'
import { TextInput } from '../../../../legacy-lib/design-kit-ui'
import { gray } from '../../../../legacy-lib/design-kit-ui/Palette'
import { LoginHeading } from '../EmailStep/style'
import { LoginFormStep } from '../LoginForm/LoginForm'
import { ButtonBox, LogoImg, PasswordAnchor } from './style'

export interface IPasswordStepProps {
  password: string
  error?: string
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onStepChange: (step: LoginFormStep) => void
  onSubmit: () => void
}

export interface IPasswordStepState {
  showPassword: boolean
}

export default class PasswordStep extends React.Component<IPasswordStepProps, IPasswordStepState> {
  constructor(props: IPasswordStepProps) {
    super(props)

    this.state = {
      showPassword: false,
    }
  }

  public onKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation()
    if (event.key === 'Enter') {
      this.props.onSubmit()
    }
  }

  private handleLockClick = () => this.setState({ showPassword: !this.state.showPassword })

  public render() {
    return (
      <Box animation="zoomIn" margin="medium">
        <Box align="center" justify="center">
          <LogoImg src={getLogoUrl()} />

          <Box justify="center" align="center" margin="medium">
            <LoginHeading size="small" level="3">
              Login
            </LoginHeading>
          </Box>
          <Box>
            <TextInput
              value={this.props.password}
              onChange={this.props.onPasswordChange}
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Digite sua senha"
              label="Senha"
              reverse
              autoFocus={true}
              onKeyDown={this.onKeyDown}
              errorIntent={!!this.props.error}
            />
            <PasswordAnchor
              color={gray.aluminium}
              icon={this.state.showPassword ? <Hide size="32px" /> : <FormView size="32px" />}
              onClick={this.handleLockClick}
            />
          </Box>
        </Box>
        <Box>{this.props.error && <ErrorString text={this.props.error} />}</Box>
        <ButtonBox onClick={() => this.props.onSubmit()}>Continuar</ButtonBox>
      </Box>
    )
  }
}
