import * as React from 'react'
import styled from 'styled-components'
import { Button } from '../../../atoms/Buttons/Button'
import { ReactComponent as WarningIcon } from '../../../common/assets/images/warning-icon.svg'
import { black, gray, green, white } from '../../../ui/utils/_colors'

interface ContractsSimulatorErrorProps {
  handleGoBack: () => void
}
export const ContractsSimulatorError: React.FC<ContractsSimulatorErrorProps> = ({ handleGoBack }) => {
  return (
    <>
      <MainContainer>
        <IconContainer>
          <WarningIcon />
        </IconContainer>

        <Title>Erro ao realizar simulação</Title>
        <Text>Encontramos um erro e não foi possível realizar essa simulação. Por favor, tente novamente.</Text>
      </MainContainer>

      <Footer>
        <Button text={'Voltar'} textColor={green.dark2} backgroundColor={white.primary} border={`1px solid ${green.dark2}`} onClick={handleGoBack} />
      </Footer>
    </>
  )
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: auto;
  width: 100%;
  padding: 24px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 16px 0px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  color: ${black.primary};
  text-align: center;
`

const Text = styled.span`
  font-family: Inter;
  color: ${gray.scale800};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40vh;
  padding: 24px;
`
