import React from 'react'
import QRCode from 'react-qr-code'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import { Button } from '../../atoms/Buttons/Button'
import { CloseButton } from '../../atoms/Buttons/CloseButton'
import { ReactComponent as UpdateBorrowerDataImg } from '../../common/assets/update-borrower-data/update_borrower_data.svg'
import { getConfig } from '../../config/config'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { gray, green, white } from '../../ui/utils/_colors'

interface Props extends RouteComponentProps {}

const UpdateBorrowerDataPage = (props: Props) => {
  const updateCustomerDataUrl = getConfig().customers.updateCustomersDataUrl

  const handleUpdateBorrowerDataClick = () => {
    window.open(updateCustomerDataUrl, '_blank')
    props.history.goBack()
  }

  const handleBackButton = () => {
    props.history.goBack()
  }

  return (
    <MainContainer>
      <ContentWrapper>
        <CloseButton onCloseButtonClick={handleBackButton} />
        <PageContent>
          <OperatorInstructions>
            <UpdateBorrowerDataImg />
            <Title> Empreste este aparelho ao cliente </Title>
            <Text style={{ marginTop: '2vh' }}> A próxima etapa inclui validação facial e deve ser concluída pelo próprio cliente. Por favor, entregue o celular para que ele possa continuar. </Text>
          </OperatorInstructions>

          <Divider />

          <BorrowerInstructions>
            <Text> Se o cliente preferir realizar a alteração no próprio celular, oriente-o a ler o QR Code abaixo. </Text>
            <QrCode size={200} value={updateCustomerDataUrl} />
          </BorrowerInstructions>

          <Button onClick={handleUpdateBorrowerDataClick} text={'Ir para alteração cadastral'} textColor={white.primary} backgroundColor={green.dark2} />
        </PageContent>
      </ContentWrapper>
    </MainContainer>
  )
}
export default UpdateBorrowerDataPage

const MainContainer = styled(PhoneLayout).attrs({
  style: {
    padding: 0,
  },
})``

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 91%;

  margin: 20px 20px 20px 20px;
`

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  height: 100%;
`

const OperatorInstructions = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`

const BorrowerInstructions = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${gray.scale800};

  margin-top: 2vh;
`

const QrCode = styled(QRCode)`
  margin-top: 4vh;
  width: 100%;
`

const Text = styled.span`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${gray.scale800};
`

const Divider = styled.div`
  height: 1.5px;
  background-color: ${gray.scale400};
`
