import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { CloseButton } from '../../atoms/Buttons/CloseButton'
import { isTransactionalCreditAllowed } from '../../common/AccessHelper'
import LocalStorageWrapper, { LocalStorageItem } from '../../common/assets/utils/LocalStorageWrapper'
import AvailableProductsSection from '../../molecules/AvailableProducts/AvailableProductsSection'
import OperatorServiceCenterBorrowerInfo from '../../molecules/OperatorServiceCenter/borrowerData/OperatorServiceCenterBorrowerInfo'
import OperatorServiceCenterIncentive from '../../molecules/OperatorServiceCenter/borrowerData/OperatorServiceCenterIncentive'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { gray } from '../../ui/utils/_colors'
import { UserAvailableProducts } from '../AvailableProducts/UserAvailableProducts'
import CouponDetails from './CouponDetails'

const OperatorServiceCenterBorrowerData: React.FC = () => {
  const localStorageConfigurations = LocalStorageWrapper.getItem(LocalStorageItem.CONFIGURATIONS)

  const { borrowerData } = useTypedSelector(state => ({
    borrowerData: state.operatorServiceCenter.borrower,
  }))

  const dispatch = useDispatch()

  const handleBackButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
  }

  return (
    <MainContainer>
      <BorrowerContainer>
        <CloseButtonContainer>
          <CloseButton onCloseButtonClick={handleBackButton} />
        </CloseButtonContainer>

        {borrowerData?.credit?.incentiveCategory && !isTransactionalCreditAllowed(localStorageConfigurations) && (
          <OperatorServiceCenterIncentive incentiveCategory={borrowerData?.credit?.incentiveCategory} incentiveValue={borrowerData?.operator?.incentiveValue} />
        )}
        <OperatorServiceCenterBorrowerInfo borrower={borrowerData} />
        {borrowerData?.coupon && <CouponDetails coupon={borrowerData?.coupon!} showStoreAlert={false} />}

        <AvailableProducts>
          <AvailableProductsSection
            userAvailableProducts={UserAvailableProducts({
              borrowerId: borrowerData?.borrower?.id,
              document: borrowerData?.borrower?.document,
              options: {
                budget: {
                  isAllowed: true,
                },
                contractSimulation: {
                  isAllowed: !!borrowerData?.options?.isContractSimulationAllowed,
                  simulationMaxValue: borrowerData?.credit?.availableLimit!,
                  simulationFlowStartedFrom: PagesPathEnum.OPERATOR_SERVICE_CENTER,
                },
                updateBorrowerData: {
                  isAllowed: true,
                },
              },
            })}
          />
        </AvailableProducts>
      </BorrowerContainer>
    </MainContainer>
  )
}

export default OperatorServiceCenterBorrowerData

const CloseButtonContainer = styled.div`
  padding-bottom: 16px;
`

const MainContainer = styled.div`
  height: 100vh;
`

const BorrowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 24px 24px 0px 24px;
`

const AvailableProducts = styled.div`
  flex-grow: 1;
  margin: 24px -24px 0 -24px;
  background: ${gray.light2};
  overflow-y: auto;
`
