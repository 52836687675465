import { Box } from 'grommet'
import * as React from 'react'
import { HistoryProps } from '../../../../App'
import { getLogoDarkBackgroundUrl } from '../../../../common/AssetsHelper'
import { Role } from '../../../../common/constants'
import { Anchor, Header } from '../../../../legacy-lib/design-kit-ui'
import { green } from '../../../../legacy-lib/design-kit-ui/Palette'
import { bffParceirosApiService } from '../../../../services/bff'
import { NavItem } from '../Header/Header'
import SideBarMenu from '../SideBar/SideBar'
import { LogoImg, TopAnchorStyle } from './style'

export interface INavBarMobileProps extends HistoryProps {
  userRole: Role
  navItems: NavItem[]
}

export interface INavBarMobileState {
  openSideBar: boolean
  openSearch: boolean
}

export default class NavBarMobile extends React.Component<INavBarMobileProps, INavBarMobileState> {
  constructor(props: INavBarMobileProps) {
    super(props)

    this.state = {
      openSideBar: false,
      openSearch: false,
    }
  }

  onLogoutClick = () => {
    bffParceirosApiService.auth.logout()
  }

  onOpenSideBar = () => {
    this.setState({ openSideBar: true })
  }

  onCloseSideBar = () => {
    this.setState({ openSideBar: false })
  }

  onOpenSearch = () => {
    this.setState({ openSearch: true })
  }

  onCloseSearch = () => {
    this.setState({ openSearch: false })
  }

  public render() {
    const user = bffParceirosApiService.auth.getUser()
    let retailerId = user?.retailerId

    const topNavItemsAnchorsMobile = this.props.navItems.map((navItem, idx) => {
      if (navItem.label === 'Sair' || navItem.label === 'Configurações') return undefined

      // If no retailer array is given, check only if the user has the necessary role
      if (
        (!retailerId || !navItem.retailers || navItem.retailers.length === 0) &&
        navItem.authorizedRoles.includes(this.props.userRole)
      ) {
        return (
          <Box key={idx} direction="row" gap="medium">
            {React.cloneElement(navItem.icon, {
              color: green.darkPastel,
              width: '30px',
              height: '30px',
            })}
            <TopAnchorStyle bold href={navItem.path} label={navItem.label} color="white" />
          </Box>
        )
      } else if (
        retailerId &&
        navItem.retailers &&
        navItem.retailers.length !== 0 &&
        navItem.retailers.find(id => retailerId === id) &&
        navItem.authorizedRoles.includes(this.props.userRole)
      ) {
        return (
          <Box key={idx} direction="row" gap="medium">
            {React.cloneElement(navItem.icon, {
              color: green.darkPastel,
              width: '30px',
              height: '30px',
            })}
            <TopAnchorStyle bold href={navItem.path} label={navItem.label} color="white" />
          </Box>
        )
      } else {
        return undefined
      }
    })

    const bottomNavItemsAnchorsMobile = this.props.navItems.map(
      (navItem, idx) =>
        navItem.authorizedRoles.includes(this.props.userRole) &&
        (navItem.label === 'Sair' || navItem.label === 'Configurações') && (
          <Anchor
            key={idx}
            icon={navItem.icon}
            href={navItem.path}
            onClick={() => navItem.label === 'Sair' && this.onLogoutClick()}
            label={navItem.label}
            color="white"
          />
        )
    )

    return (
      <Header
        mobile
        logo={
          <Anchor href="/">
            <LogoImg src={getLogoDarkBackgroundUrl()} />
          </Anchor>
        }
        isSideBarOpen={this.state.openSideBar}
        openSideBar={this.onOpenSideBar}
        sideBar={
          <SideBarMenu
            history={this.props.history}
            topNavItems={topNavItemsAnchorsMobile}
            bottomNavItems={bottomNavItemsAnchorsMobile}
            onCloseSideBar={this.onCloseSideBar}
          />
        }
      >
        {/* TO DO: Search Side Bar */}
        {/* <Anchor color="white" icon={<Search />} onClick={this.onOpenSearch} />
        {this.state.openSearch && <SearchSideBar history={this.props.history} onCloseSearch={this.onCloseSearch} />} */}
      </Header>
    )
  }
}
