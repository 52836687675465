import { createTheme, ThemeProvider } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import { DocumentErrors, StoreErrors, UnmappedErrorMessages } from '../../common/ErrorStrings'
import { validate_cpf } from '../../common/Validators'
import TransactionalCreditForm from '../../organisms/TransactionalCredit/TransactionalCreditForm'
import TransactionalCreditResult from '../../organisms/TransactionalCredit/TransactionalCreditResult'
import { useTypedSelector } from '../../redux/reducers/selectors'
import TransactionalCreditSliceReducer from '../../redux/reducers/tx-credit/tx-credit.reducer'
import { CreateTransactionalCreditEvaluationReq } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { green } from '../../ui/utils/_colors'
import OperatorsHome from '../Operators/OperatorsHome'
import TransactionalCreditHelper from './TransactionalCreditHelper'
import { TransactionalCreditPages } from './TransactionalCreditPages'

const theme = createTheme({
  palette: {
    primary: {
      main: green.primary300,
    },
  },
})

const TransactionalCredit: React.FC = () => {
  const { page, transactionalCreditEvaluation, isLoadingTransactionalCreditEvaluation, isTransactionalCreditEvaluationError } = useTypedSelector(state => ({
    page: state.transactionalCredit.page,
    transactionalCreditEvaluation: state.transactionalCredit.transactionalCreditEvaluation,
    isLoadingTransactionalCreditEvaluation: state.transactionalCredit.isLoadingTransactionalCreditEvaluation,
    isTransactionalCreditEvaluationError: state.transactionalCredit.isTransactionalCreditEvaluationError,
  }))

  const [formDocument, setFormDocument] = useState<string>('')
  const [isRunningPreChecks, setIsRunningPreChecks] = useState<boolean>(false)

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!!transactionalCreditEvaluation) dispatch(TransactionalCreditSliceReducer.actions.setPage(TransactionalCreditPages.Result))
  }, [transactionalCreditEvaluation])

  React.useEffect(() => {
    if (isTransactionalCreditEvaluationError) {
      dispatch(TransactionalCreditSliceReducer.actions.resetTransactionalCreditEvaluation())
      alert(UnmappedErrorMessages.UNEXPECTED_ERROR)
    }
  }, [isTransactionalCreditEvaluationError])

  const handleRedo = () => {
    dispatch(TransactionalCreditSliceReducer.actions.resetTransactionalCreditEvaluation())
    setFormDocument('')
    dispatch(TransactionalCreditSliceReducer.actions.setPage(TransactionalCreditPages.Document))
  }

  const handleConfirmDocument = (document: string, storeId?: string) => {
    if (!storeId) {
      return toaster.showErrorToast(StoreErrors.STORE_CONFIGURATION_REQUIRED)
    }

    if (!validate_cpf(document)) {
      return toaster.showErrorToast(DocumentErrors.INVALID_DOCUMENT)
    }

    setFormDocument(document)
    dispatch(TransactionalCreditSliceReducer.actions.setPage(TransactionalCreditPages.Form))
  }

  const handleTxCreditEvaluationCreation = async (request: CreateTransactionalCreditEvaluationReq) => {
    if (!request.storeId) {
      return
    }

    setIsRunningPreChecks(true)
    await TransactionalCreditHelper.runPreChecks(request.document, request.storeId).catch(() => undefined)
    dispatch(TransactionalCreditSliceReducer.actions.createTransactionalCreditEvaluation(request))
    setIsRunningPreChecks(false)
  }

  const renderPage = () => {
    switch (page) {
      case TransactionalCreditPages.Document:
        return <OperatorsHome onConfirmDocument={handleConfirmDocument} />
      case TransactionalCreditPages.Form:
        return <TransactionalCreditForm document={formDocument} onConfirmValue={handleTxCreditEvaluationCreation} onRedo={handleRedo} />
      case TransactionalCreditPages.Result:
        return <TransactionalCreditResult evaluationResult={transactionalCreditEvaluation!!} onRedo={handleRedo} />
    }
  }

  return (
    <Container>
      <ThemeProvider theme={theme}>{renderPage()}</ThemeProvider>
      {(isLoadingTransactionalCreditEvaluation || isRunningPreChecks) && (
        <LoadingSpinner text={isRunningPreChecks ? 'Aguarde, essa avaliação pode demorar um pouco. Permaneça na tela...' : 'Analisando proposta...'} />
      )}
    </Container>
  )
}

export default TransactionalCredit

const Container = styled(PhoneLayout).attrs({
  style: {
    padding: 0,
  },
})``
