import { Box } from 'grommet'
import * as React from 'react'
import ErrorString from '../../../../atoms/ErrorString/ErrorString'
import { getLogoUrl } from '../../../../common/AssetsHelper'
import { TextInput } from '../../../../legacy-lib/design-kit-ui'
import { LoginFormStep } from '../LoginForm/LoginForm'
import { ButtonBox, LoginHeading, LogoImg } from './style'

export interface IEmailStepProps {
  username: string
  error?: string
  onStepChange: (step: LoginFormStep) => void
  onUsernameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export interface IEmailStepState {}

export default class EmailStep extends React.Component<IEmailStepProps, IEmailStepState> {
  constructor(props: IEmailStepProps) {
    super(props)

    this.state = {}
  }

  public onKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation()
    if (event.key === 'Enter') {
      this.props.onStepChange(LoginFormStep.PASSWORD_STEP)
    }
  }

  public render() {
    return (
      <Box margin="medium">
        <Box align="center" justify="center">
          <LogoImg src={getLogoUrl()} />

          <Box justify="center" align="center" margin="medium">
            <LoginHeading size="small" level="3">
              Login
            </LoginHeading>
          </Box>
          <TextInput
            value={this.props.username}
            onChange={this.props.onUsernameChange}
            placeholder="Digite seu e-mail"
            autoFocus={true}
            type="email"
            label="E-mail"
            errorIntent={!!this.props.error}
            onKeyDown={this.onKeyDown}
          />
        </Box>
        <Box>{this.props.error && <ErrorString text={this.props.error} />}</Box>
        <ButtonBox onClick={() => this.props.onStepChange(LoginFormStep.PASSWORD_STEP)}>Continuar</ButtonBox>
      </Box>
    )
  }
}
