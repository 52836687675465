import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CameraIcon } from '../../common/assets/biometry-instructions/bi_camera.svg'
import { ReactComponent as CellphoneIcon } from '../../common/assets/biometry-instructions/bi_cellphone.svg'
import { ReactComponent as FaceIcon } from '../../common/assets/biometry-instructions/bi_face.svg'
import { ReactComponent as LightIcon } from '../../common/assets/biometry-instructions/bi_light.svg'
import { ReactComponent as WarningIcon } from '../../common/assets/biometry-instructions/bi_warning.svg'
import ApplicationLayout from '../../organisms/Application/ApplicationLayout'
import BiometryInstructionItem from './BiometryInstructionItem'
import PermissionChecker from './PermissionsChecker'

const biometryLivenessInstructions = [
  { icon: <LightIcon />, text: 'Garanta uma boa iluminação.' },
  { icon: <FaceIcon />, text: 'Não utilize bonés ou óculos.' },
  { icon: <CameraIcon />, text: 'Sua câmera deve estar limpa.' },
  { icon: <CellphoneIcon />, text: 'Siga as instruções na tela.' },
  { icon: <WarningIcon />, text: 'Não saia do site até a conclusão.' },
]

interface Props {
  handleNext: () => void
}

const BiometryLivenessInstructionPage = ({ handleNext }: Props) => {
  return (
    <ApplicationLayout title={'Reconhecimento facial'} subtitle={'Faremos o reconhecimento facial para garantir a sua segurança.'} overrideNextPage={handleNext} isNextButtonEnabled={true}>
      <Container>
        {biometryLivenessInstructions.map(item => (
          <BiometryInstructionItem icon={item.icon} text={item.text} style={{ marginTop: '3vh' }} />
        ))}
      </Container>

      <PermissionsContainer>
        <PermissionChecker />
      </PermissionsContainer>
    </ApplicationLayout>
  )
}

export default BiometryLivenessInstructionPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const PermissionsContainer = styled.div`
  margin-top: 3vh;
`
