import React from 'react'
import styled from 'styled-components'
import { ReactComponent as InfotipIcon } from '../../../../common/assets/super-purchase/infotip.svg'
import { DeniedCreditStatus, EvaluationResultEnum } from '../../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { black, blue, gray, green, orange } from '../../../../ui/utils/_colors'

export interface AlertBoxProps {
  evaluationResult: EvaluationResultEnum
  evaluationExpirationDate?: string
  evaluationStoreName?: string
  deniedStatus?: DeniedCreditStatus
}

interface AlertBoxUI {
  title: string
  text: string | React.ReactNode
  cardStyle: {
    backgroundColor: string
    borderColor: string
  }
  icon?: {
    component: React.ElementType
    fill: string
  }
}

const AlertBox: React.FC<AlertBoxProps> = ({ evaluationResult, evaluationExpirationDate, evaluationStoreName, deniedStatus }) => {
  const defineAlertBoxData = (): AlertBoxUI => {
    switch (evaluationResult) {
      case EvaluationResultEnum.APPROVED:
      case EvaluationResultEnum.PARTIALLY_APPROVED:
        return {
          title: 'Super Compra disponível',
          text: (
            <>
              Proposta ativa no caixa até {evaluationExpirationDate} na loja <BoldText>{evaluationStoreName}</BoldText>.
            </>
          ),
          cardStyle: {
            backgroundColor: green.light4,
            borderColor: green.lemon,
          },
        }
      case EvaluationResultEnum.DENIED:
        if (deniedStatus === DeniedCreditStatus.EVALUATION_DENIED_AND_AVAILABLE_LIMIT_BELOW_THRESHOLD) {
          return {
            title: '',
            text: 'Oriente o cliente a pagar suas parcelas em aberto para voltar a comprar.',
            cardStyle: { backgroundColor: blue.alert50, borderColor: blue.alert50 },
            icon: {
              component: InfotipIcon,
              fill: blue.cobalt,
            },
          }
        }

        return {
          title: 'Super Compra não foi aprovada',
          text: 'O limite extra da Super Compra não foi aprovado, mas o cliente pode aproveitar o limite concedido.',
          cardStyle: { backgroundColor: orange.light, borderColor: orange.amber1 },
        }
    }
  }

  const alertBoxData = defineAlertBoxData()

  return (
    <MainContainer backgroundColor={alertBoxData.cardStyle.backgroundColor} borderColor={alertBoxData.cardStyle.borderColor}>
      <Container>
        {alertBoxData.icon ? <alertBoxData.icon.component style={{ alignSelf: 'center', marginRight: '18px' }} width={35} height={35} fill={alertBoxData.icon.fill} /> : undefined}
        <InfoContainer>
          <Title>{alertBoxData.title}</Title>
          <Text>{alertBoxData.text}</Text>
        </InfoContainer>
      </Container>
    </MainContainer>
  )
}

export default AlertBox

interface AlertBoxStyleProps {
  backgroundColor: string
  borderColor: string
}

const MainContainer = styled.div<AlertBoxStyleProps>`
  background: ${(props: AlertBoxStyleProps) => props.backgroundColor};
  border: 1px solid ${(props: AlertBoxStyleProps) => props.borderColor};
  border-radius: 4px;
  text-align: left;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`

const InfoContainer = styled.text`
  display: flex;
  flex-direction: column;
  justify-content: start;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: ${black.primary};
`

const Text = styled.text`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${gray.scale700};
  margin-top: 8px;
`

const BoldText = styled.text`
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${gray.scale700};
`
