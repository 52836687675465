import { Icon } from '@blueprintjs/core'
import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import analyticsService from '../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../services/analytics/events'
import { gray } from '../../ui/utils/_colors'

interface AlertDropdownProps {
  backgroundColor: string
  icon: React.ReactElement
  dropdownHiddenText: string | JSX.Element
  dropdownVisibleText: string | JSX.Element
}

export const AlertDropdown: React.FC<AlertDropdownProps> = ({ backgroundColor, icon, dropdownHiddenText, dropdownVisibleText }) => {
  const [showHiddenText, setShowHiddenText] = useState<boolean>(false)

  const handleDropdownOnClick = () => {
    analyticsService.sendEvent(!showHiddenText ? AnalyticsEvents.info_expanded : AnalyticsEvents.info_collapsed)

    setShowHiddenText(!showHiddenText)
  }

  return (
    <MainContainer backgroundColor={backgroundColor} onClick={handleDropdownOnClick}>
      <AlertDropdownRowView>
        {icon}
        {!showHiddenText ? <AlertText style={{ whiteSpace: 'nowrap' }}>{dropdownVisibleText}</AlertText> : <AlertText>{dropdownHiddenText}</AlertText>}
        <DropdownIcon icon={showHiddenText ? 'chevron-up' : 'chevron-down'} size={16} color={gray.dim} />
      </AlertDropdownRowView>
    </MainContainer>
  )
}

const MainContainer = styled.div<AlertDropdownProps>`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  background: ${(props: AlertDropdownProps) => props.backgroundColor};
  cursor: pointer;
`

const AlertDropdownRowView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
`

const AlertText = styled.text`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${gray.scale800};
  width: 250px;
`

const DropdownIcon = styled(Icon)`
  margin-left: 8px;
`
