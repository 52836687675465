import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { bffParceirosApiService } from '../../../services/bff'
import ContractsSimulatorSliceReducer, { ContractSimulation, SimulateContract } from './contracts-simulator.reducer'

function* simulateContract(action: PayloadAction<SimulateContract>) {
  const { borrowerId, principal } = action.payload

  const localStorageStore = bffParceirosApiService.coordinator.getStore()

  try {
    const response: ContractSimulation = yield bffParceirosApiService.coordinator.simulateInStoreContract({ borrowerId, principal, storeId: localStorageStore!.id })

    yield put(ContractsSimulatorSliceReducer.actions.simulateContractSuccess(response))
  } catch (error) {
    yield put(ContractsSimulatorSliceReducer.actions.simulateContractError(error))
  }
}

const contractsSimulatorSaga = [takeLatest(ContractsSimulatorSliceReducer.actions.simulateContract, simulateContract)]

export default contractsSimulatorSaga
