import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history } from '../../App'
import { Button } from '../../atoms/Buttons/Button'
import { CloseButton } from '../../atoms/Buttons/CloseButton'
import { formatCellphoneWithoutNinthDigit, formatDDGPhoneNumber } from '../../cash-register/common/formatCellPhone'
import { ReactComponent as BlockIcon } from '../../common/assets/images/block-icon.svg'
import { ReactComponent as WarningIcon } from '../../common/assets/images/warning-icon.svg'
import * as featureFlag from '../../config/config'
import { white } from '../../legacy-lib/design-kit-ui/Palette'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import { OPERATOR_SERVICE_CENTER_PAGES } from '../../pages/OperatorServiceCenter/enums/operator-service-center-pages.enum'
import { OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE } from '../../pages/OperatorServiceCenter/enums/operator-service-center-restrictions.enum'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { black, gray, green } from '../../ui/utils/_colors'

const WARNING_RESTRICTIONS = [
  OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_UNDER_ANALYSIS,
  OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_MANUAL_ANALYSIS,
  OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_PRE_APPROVED,
]

const PURCHASE_BLOCK_RESTRICTIONS = [OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_BLOCKED_TO_PURCHASE, OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_DISABLE_TO_PURCHASE]

const OTHERS_BLOCK_RESTRICTIONS = [OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_DENIED, OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.OVERDUE_INSTALLMENTS]

interface BorrowerRestrictionProps {
  restriction: string
}

const OperatorServiceCenterBorrowerRestriction = (props: BorrowerRestrictionProps): JSX.Element => {
  const { restriction } = props

  const dispatch = useDispatch()

  React.useEffect(() => {
    return () => {
      dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    }
  }, [dispatch])

  const handleIcon = () => {
    if (
      PURCHASE_BLOCK_RESTRICTIONS.includes(restriction as OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE) ||
      OTHERS_BLOCK_RESTRICTIONS.includes(restriction as OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE)
    ) {
      return <BlockIcon width={'56px'} height={'56px'} />
    } else {
      return <WarningIcon width={'56px'} height={'56px'} />
    }
  }

  const handleText = () => {
    if (WARNING_RESTRICTIONS.includes(restriction as OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE)) {
      return 'Ainda não foi possível concluir a avaliação deste cliente. Cheque novamente em outro momento.'
    }
    if (PURCHASE_BLOCK_RESTRICTIONS.includes(restriction as OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE)) {
      return 'Solicite ao cliente que entre em contato com o atendimento via WhatsApp ou ligação telefônica.'
    }
    if (restriction === OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_DENIED) {
      return 'Infelizmente não foi possível aprovar o cadastro desse cliente. '
    }
    if (restriction === OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.OVERDUE_INSTALLMENTS) {
      return 'Solicite ao cliente para verificar a situação dos seus pagamentos no app ou contatar o atendimento.'
    }
  }

  const handleBackToHomeButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE))
    history.push(PagesPathEnum.HOME_PAGE)
  }

  const shouldRenderCustomerServiceInfo =
    PURCHASE_BLOCK_RESTRICTIONS.includes(restriction as OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE) || restriction == OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.OVERDUE_INSTALLMENTS

  return (
    <>
      <MainContainer>
        <CloseButtonContainer>
          <CloseButton onCloseButtonClick={handleBackToHomeButton} />
        </CloseButtonContainer>

        <Header>
          <IconContainer>{handleIcon()}</IconContainer>
          <Title>{restriction}</Title>
        </Header>

        <TextContainer>
          <Text>{handleText()}</Text>
        </TextContainer>

        {shouldRenderCustomerServiceInfo && (
          <CustomerServiceInfoContainer>
            <Subtitle>Central de atendimento ao cliente</Subtitle>
            <CustomerServiceInfoText>De segunda a sexta, das 9h às 23h e aos sábados, das 9h às 19h.</CustomerServiceInfoText>
            <CustomerServiceInfoText>
              {`WhatsApp: ${formatCellphoneWithoutNinthDigit(featureFlag.UME_PHONES.customerService.whatsapp)}`}
              <br />
              {`Ligação telefônica gratuita: ${formatDDGPhoneNumber(featureFlag.UME_PHONES.customerService.phoneNumber)}`}
            </CustomerServiceInfoText>
          </CustomerServiceInfoContainer>
        )}
      </MainContainer>

      <Footer hasCustomerServiceInfoContainer={shouldRenderCustomerServiceInfo}>
        <Button text={'Voltar para tela inicial'} textColor={green.dark2} backgroundColor={white.primary} border={`1px solid ${green.dark2}`} onClick={handleBackToHomeButton} />
      </Footer>
    </>
  )
}

export default OperatorServiceCenterBorrowerRestriction

interface FooterProps {
  hasCustomerServiceInfoContainer: boolean
}

const MainContainer = styled(PhoneLayout).attrs({
  style: {
    padding: '0px',
  },
})``

const CloseButtonContainer = styled.div`
  margin-top: 16px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  padding: 16px 0px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 16px 0px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  color: ${black.primary};
  text-align: center;
`

const TextContainer = styled.div`
  margin-top: 8px;
  text-align: center;
`

const Text = styled.text`
  font-family: Inter;
  font-size: 16px;
  color: ${black.primary};
`

const CustomerServiceInfoContainer = styled.div`
  display: flex;
  margin-top: 24px;
  margin-left: -24px;
  margin-right: -24px;
  padding: 24px;
  flex-direction: column;
  background: ${gray.light2};
`

const Subtitle = styled.text`
  color: ${gray.scale800};
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`

const CustomerServiceInfoText = styled.text`
  color: ${gray.scale700};
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 8px 0px;
`

const Footer = styled.div<FooterProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ hasCustomerServiceInfoContainer }: FooterProps) => (hasCustomerServiceInfoContainer ? '10vh' : '30vh')};
`
