import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { history } from '../../../App'
import LoadingSpinner from '../../../atoms/Loading/LoadingSpinner'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import ApplicationSliceReducer from '../../../redux/reducers/application/application.reducer'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../../services/bff'
import { TransactionalCreditEvaluationResult } from '../../../services/bff/transactional-credit/enums/transactional-credit-evaluation-results.enum'
import { TransactionalCreditEvaluationStatus } from '../../../services/bff/transactional-credit/enums/transactional-credit-evaluation-status.enum'
import ApplicationApproved from './ApplicationApprovedComponent'

const ApplicationApprovedPage = () => {
  const dispatch = useDispatch()
  const { isFetchingBorrowerLimit, borrowerLimit, availableLimit, applicationCoupon, cpf, borrowerId, isFetchingTransactionalCreditEvaluation, transactionalCreditEvaluation } = useTypedSelector(
    state => ({
      isFetchingBorrowerLimit: state.application.isFetchingBorrowerLimit,
      borrowerLimit: state.application.borrowerLimit,
      availableLimit: state.application.availableLimit,
      applicationCoupon: state.application.applicationCoupon,
      cpf: state.application.cpf,
      borrowerId: state.application.borrowerId,
      isFetchingTransactionalCreditEvaluation: state.application.isFetchingTransactionalCreditEvaluation,
      transactionalCreditEvaluation: state.application.transactionalCreditEvaluation,
    })
  )

  const store = bffParceirosApiService.coordinator.getStore()

  const handleReset = () => {
    dispatch(ApplicationSliceReducer.actions.resetState())
    history.push(PagesPathEnum.OPERATOR_SERVICE_CENTER)
  }

  useEffect(() => {
    dispatch(ApplicationSliceReducer.actions.fetchBorrowerLimit())
    dispatch(
      ApplicationSliceReducer.actions.fetchTransactionalCreditEvaluation({
        cpf,
        storeId: store?.id,
        retailerId: store?.retailerId,
        status: TransactionalCreditEvaluationStatus.ACCEPTED,
        results: [TransactionalCreditEvaluationResult.APPROVED, TransactionalCreditEvaluationResult.PARTIALLY_APPROVED, TransactionalCreditEvaluationResult.DENIED],
      })
    )
  }, [dispatch])

  if (isFetchingTransactionalCreditEvaluation) {
    return <LoadingSpinner />
  }

  return (
    <ApplicationApproved
      handleReset={handleReset}
      isFetchingBorrowerLimit={isFetchingBorrowerLimit}
      transactionalCreditEvaluation={transactionalCreditEvaluation}
      applicationCoupon={applicationCoupon}
      availableLimit={availableLimit}
      borrowerLimit={borrowerLimit}
      document={cpf}
      borrowerId={borrowerId}
    />
  )
}

export default ApplicationApprovedPage
