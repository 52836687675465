import styled from 'styled-components'
import { tablet } from '../../../../common/assets/utils/_breakpoints'

export const LogoImg = styled.img`
  alt: logo;
  height: 32px;
  margin-right: 24px;
`

export const NavBarComponent = styled.div`
  > div {
    &.panel-header {
      display: none;
    }
  }

  @media (min-width: ${tablet}) {
    > div {
      &.panel-header {
        display: block;
      }
      &.is-mobile {
        display: none;
      }
    }
  }
`
