import React, { useEffect, useState } from 'react'
import PermissionSwitch from '../../molecules/Biometry/PermissionSwitch'

enum Permissions {
  camera = 'camera',
  microphone = 'microphone',
  geolocation = 'geolocation',
}

interface PermissionsStatus {
  camera: PermissionState | null
  microphone: PermissionState | null
  geolocation: PermissionState | null
}

const PermissionChecker = () => {
  const [permissions, setPermissions] = useState<PermissionsStatus>({
    camera: null,
    microphone: null,
    geolocation: null,
  })

  const checkPermissions = async () => {
    try {
      const cameraPermission = await navigator.permissions.query({ name: Permissions.camera as PermissionName })
      const microphonePermission = await navigator.permissions.query({ name: Permissions.microphone as PermissionName })
      const geolocationPermission = await navigator.permissions.query({ name: Permissions.geolocation })

      setPermissions({
        camera: cameraPermission.state,
        microphone: microphonePermission.state,
        geolocation: geolocationPermission.state,
      })

      cameraPermission.onchange = () => setPermissions(prev => ({ ...prev, camera: cameraPermission.state }))
      microphonePermission.onchange = () => setPermissions(prev => ({ ...prev, microphone: microphonePermission.state }))
      geolocationPermission.onchange = () => setPermissions(prev => ({ ...prev, geolocation: geolocationPermission.state }))
    } catch (error) {
      console.error('Error checking permissions:', error)
    }
  }

  const requestPermission = async (type: Permissions) => {
    try {
      if (type === Permissions.camera || type === Permissions.microphone) {
        await navigator.mediaDevices.getUserMedia({
          video: type === Permissions.camera,
          audio: type === Permissions.microphone,
        })
      } else if (type === Permissions.geolocation) {
        await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject)
        })
      }
    } catch (error) {
      console.error(`Error requesting ${type} permission:`, error)
    }
  }

  useEffect(() => {
    checkPermissions()
  }, [])

  return (
    <div>
      <h2>Ative as permissões</h2>
      <PermissionSwitch permission={permissions.camera} name={'Câmera'} onClick={() => requestPermission(Permissions.camera)} />
      <PermissionSwitch permission={permissions.microphone} name={'Microfone'} onClick={() => requestPermission(Permissions.microphone)} />
      <PermissionSwitch permission={permissions.geolocation} name={'Localização'} onClick={() => requestPermission(Permissions.geolocation)} />
    </div>
  )
}

export default PermissionChecker
