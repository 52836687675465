import { base, Grommet, Layer, ResponsiveContext } from 'grommet'
import * as React from 'react'
import { ElementsBox } from './style'

export interface IBackgroundProps {}

export interface IBackgroundState {}

export default class Background extends React.Component<IBackgroundProps, IBackgroundState> {
  constructor(props: IBackgroundProps) {
    super(props)

    this.state = {}
  }

  public render() {
    return (
      <Grommet theme={base}>
        <ResponsiveContext.Consumer>
          {size => (
            <Layer animate={false} full>
              {size === 'small' ? (
                <ElementsBox fill background="white">
                  {this.props.children}
                </ElementsBox>
              ) : (
                <ElementsBox fill background="white">
                  {this.props.children}
                </ElementsBox>
              )}
            </Layer>
          )}
        </ResponsiveContext.Consumer>
      </Grommet>
    )
  }
}
