import React from 'react'
import { ReactComponent as AlertIcon } from '../../../../common/assets/images/alert-icon.svg'
import { ReactComponent as ApprovedCheckDiamondIcon } from '../../../../common/assets/images/approved-check-circle-diamond.svg'
import { calculateTxCreditTotalAmount } from '../../../../common/TransactionalCreditHelper'
import { PagesPathEnum } from '../../../../pages/enums/pages-path.enum'
import TransactionalCreditHelper from '../../../../pages/transactional-credit/TransactionalCreditHelper'
import analyticsService from '../../../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../../../services/analytics/events'
import { AggregatedTransactionalCreditEvaluation, DeniedCreditStatus, EvaluationResultEnum } from '../../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { UserAvailableProducts, UserAvailableProductsProps } from '../../../AvailableProducts/UserAvailableProducts'
import TransactionalCreditResultGenericScreen from '../TransactionalCreditResultGenericScreen.tsx'

interface Props {
  evaluationResult: AggregatedTransactionalCreditEvaluation
  hasCompletedApplication: boolean
  handleGoBackToStart?: () => void
}

export const TransactionalCreditDeniedResult: React.FC<Props> = (props: Props) => {
  const { evaluationResult, hasCompletedApplication, handleGoBackToStart } = props

  const defineUIData = (deniedCreditStatus: DeniedCreditStatus) => {
    switch (deniedCreditStatus) {
      case DeniedCreditStatus.PRE_APPLICATION_APPROVED_AND_EVALUATION_DENIED:
        analyticsService.sendEvent(AnalyticsEvents.sp_denied_application_approved)

        return {
          icon: <ApprovedCheckDiamondIcon />,
          title: 'Cliente com limite disponível',
          subtitle: 'Cadastre o cliente para ele utilizar o limite pré-aprovado disponível. Lembre-se de solicitar que o cliente baixe o aplicativo da Ume.',
          limit: {
            title: 'Limite pré-aprovado',
            shouldRenderProgressBar: false,
          },
          userAvailableProducts: {
            document: evaluationResult.evaluation.document,
            options: {
              application: {
                isAllowed: true,
                analyticsEvent: AnalyticsEvents.sp_denied_start_application
              },
            },
          } as UserAvailableProductsProps,
        }
      case DeniedCreditStatus.EVALUATION_DENIED_AND_AVAILABLE_LIMIT_ABOVE_THRESHOLD:
        analyticsService.sendEvent(AnalyticsEvents.sp_denied_ume_limit_above_10)

        return {
          icon: <ApprovedCheckDiamondIcon />,
          title: 'Cliente com limite disponível',
          subtitle: 'Cliente já cadastrado e com limite recorrente disponível para comprar na sua loja.',
          limit: {
            title: 'Valor total disponível para comprar',
            shouldRenderProgressBar: true,
          },
          userAvailableProducts: {
            borrowerId: evaluationResult?.applicationSetup?.borrowerId,
            document: evaluationResult.evaluation.document,
            options: {
              contractSimulation: {
                isAllowed: !!evaluationResult.options?.isContractSimulationAllowed,
                simulationMaxValue: calculateTxCreditTotalAmount(evaluationResult.evaluation.policy?.maxAmount ?? 0, borrowerAvailableLimit),
                simulationFlowStartedFrom: PagesPathEnum.TRANSACTIONAL_CREDIT_PAGE,
              },
              updateBorrowerData: {
                isAllowed: true,
              },
            },
          } as UserAvailableProductsProps,
        }
      case DeniedCreditStatus.EVALUATION_DENIED_AND_AVAILABLE_LIMIT_BELOW_THRESHOLD:
        analyticsService.sendEvent(AnalyticsEvents.sp_denied_ume_limit_below_10)

        return {
          icon: <AlertIcon />,
          title: 'Cliente não foi aprovado e está sem limite suficiente',
          subtitle: 'Cliente já é cadastrado, mas está com limite muito baixo e não obteve limite extra para comprar na sua loja.',
          limit: {
            title: 'Valor total disponível para comprar',
            shouldRenderProgressBar: false,
          },
          alertBox: {
            evaluationResult: EvaluationResultEnum.DENIED,
            deniedStatus: deniedCreditStatus,
          },
          userAvailableProducts: {
            borrowerId: evaluationResult?.applicationSetup?.borrowerId,
            document: evaluationResult.evaluation.document,
            options: {
              updateBorrowerData: {
                isAllowed: true,
              },
            },
          } as UserAvailableProductsProps,
        }
    }
  }

  const borrowerAvailableLimit = evaluationResult.limit?.availableLimit ?? 0

  const deniedStatus = TransactionalCreditHelper.defineDeniedStatus(hasCompletedApplication, borrowerAvailableLimit)
  const deniedData = defineUIData(deniedStatus)
  const alertBox = deniedData.alertBox ? deniedData.alertBox : undefined

  return (
    <TransactionalCreditResultGenericScreen
      style={{
        mainContainerPadding: '24px 24px 0px 24px',
      }}
      shouldRenderCloseButton
      icon={deniedData.icon}
      title={deniedData.title}
      subtitle={deniedData.subtitle}
      limits={{ title: deniedData.limit.title, totalAmount: borrowerAvailableLimit, recurrenceAvailableAmount: borrowerAvailableLimit }}
      renderProgressBar={deniedData.limit.shouldRenderProgressBar}
      onCloseButtonClick={handleGoBackToStart}
      alertBox={alertBox}
      userAvailableProducts={UserAvailableProducts(deniedData.userAvailableProducts)}
    />
  )
}
