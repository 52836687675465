import React, { RefObject } from 'react'
import { NumberFormatValues } from 'react-number-format'
import styled from 'styled-components'
import { PhoneVerificationStep } from '../../cash-register/purchases-old/Purchases'
import { mobile } from '../../common/assets/utils/_breakpoints'
import { SourceProduct } from '../../services/bff/coordinator/dto/Contracts.dto'
import { IPhoneCommunicationChannel } from '../../services/bff/originations/dto/PhoneVerificationRecord.dto'
import PurchaseConfirmationPopup from './PurchaseConfirmationPopup'
import PurchaseInstallmentsOptionsCard, { PurchaseConditions } from './PurchaseInstallmentsOptionsCard'
import PurchaseResumeCard from './PurchaseResumeCard'

interface Props {
  purchaseValue: string
  purchaseConditions: PurchaseConditions[]
  couponDiscount: number
  selectedPurchaseCondition: number
  isVerificationDialogOpen: boolean
  isPurchaseConfirmed: boolean
  authorizationStrategy: string
  formattedCellphone: string
  verificationCode: string
  phoneVerificationStep: PhoneVerificationStep
  countDownSeconds: number
  isCountDownRunning: boolean
  cellphone: string
  togglePurchaseConditionSelected: (purchaseConditionIdx: number) => void
  onKeyPressTableRow: (index: number, event: React.KeyboardEvent) => void
  openVerificationDialog: () => void
  startVerificationProcess: () => void
  handleKeyPressCancel: (event: React.KeyboardEvent) => void
  confirmPurchaseButtonRef: RefObject<HTMLButtonElement>
  cancelPurchase: () => void
  smsCodeInputRef: RefObject<HTMLInputElement>
  handleKeyPressPhoneVerification: (event: React.KeyboardEvent) => void
  onVerificationCodeChange: (values: NumberFormatValues) => void
  onSendVerificationCodeClick: () => void
  askForVerfication: (proposalId: string, communicationChannel?: IPhoneCommunicationChannel) => void
  finishPurchase: () => void
  sourceProduct?: string
  smartphoneIMEI: string
  handleKeyPressSmartphoneIMEIVerification: (event: React.KeyboardEvent) => void
  onSmartphoneIMEIChange: (values: NumberFormatValues) => void
  needSendSmartphoneImei: boolean
  smartphoneIMEILockConfirmationError?: string,
  isLoadingSmartphoneIMEILockConfirmation: boolean,
  isPurchaseConfirmationLoading: boolean
  downPayment?: number
  purchaseValueMinusDownPayment?: number
  principal: number
  productPrice?: number
  smartphoneApiVersion?: string
}

export const PurchaseContractOptions: React.FC<Props> = props => {
  const {
    purchaseValue,
    purchaseConditions,
    couponDiscount,
    selectedPurchaseCondition,
    isVerificationDialogOpen,
    isPurchaseConfirmed,
    authorizationStrategy,
    formattedCellphone,
    verificationCode,
    phoneVerificationStep,
    countDownSeconds,
    isCountDownRunning,
    cellphone,
    togglePurchaseConditionSelected,
    onKeyPressTableRow,
    openVerificationDialog,
    startVerificationProcess,
    handleKeyPressCancel,
    confirmPurchaseButtonRef,
    cancelPurchase,
    smsCodeInputRef,
    handleKeyPressPhoneVerification,
    onVerificationCodeChange,
    onSendVerificationCodeClick,
    askForVerfication,
    finishPurchase,
    sourceProduct,
    smartphoneIMEI,
    handleKeyPressSmartphoneIMEIVerification,
    onSmartphoneIMEIChange,
    needSendSmartphoneImei,
    smartphoneIMEILockConfirmationError,
    isLoadingSmartphoneIMEILockConfirmation,
    isPurchaseConfirmationLoading,
    downPayment,
    purchaseValueMinusDownPayment,
    principal,
    productPrice,
    smartphoneApiVersion
  } = props

  const hasFinancingOptions = purchaseConditions.length > 0
  const selectedInstallment = purchaseConditions.find(condition => condition.isSelected && condition.isFocused)

  return (
    <PurschaseOptionsSection>
      <PurschaseOptionsContainer>
        <PurchaseOptionsCard>
          {hasFinancingOptions && (
            <PurchaseInstallmentsOptionsCard
              togglePurchaseConditionSelected={togglePurchaseConditionSelected}
              onKeyPressTableRow={onKeyPressTableRow}
              purchaseConditions={purchaseConditions}
              sourceProduct={sourceProduct}
            />
          )}
        </PurchaseOptionsCard>
        <PurchaseResumeCardContainer>
          {selectedInstallment ? (
            <PurchaseResumeCard
              purchaseValue={purchaseValue}
              interestRate={selectedInstallment.interestRate}
              iof={selectedInstallment.iof}
              installments={selectedInstallment.installments}
              installmentValue={selectedInstallment.installmentValue}
              installmentValueWithoutInterest={
                sourceProduct == SourceProduct.INTEREST_PAID_BY_RETAILER
                  ? selectedInstallment.installmentValueWithoutInterest
                  : undefined
              }
              firstInstallmentDueDate={selectedInstallment.firstInstallmentDueDate}
              couponDiscount={couponDiscount}
              selectedPurchaseCondition={selectedPurchaseCondition}
              openVerificationDialog={openVerificationDialog}
              customerFeeRate={selectedInstallment.customerFeeRate}
              customerFeeValue={selectedInstallment.customerFeeValue}
              dueDateRule={selectedInstallment.dueDateRule}
              sourceProduct={sourceProduct}
              downPayment={downPayment}
              purchaseValueMinusDownPayment={purchaseValueMinusDownPayment}
              principal={principal}
              productPrice={productPrice}
            />
          ) : null}
        </PurchaseResumeCardContainer>
      </PurschaseOptionsContainer>
      <PurchaseConfirmationPopup
        purchaseConditions={purchaseConditions}
        selectedPurchaseCondition={selectedPurchaseCondition}
        isVerificationDialogOpen={isVerificationDialogOpen}
        isPurchaseConfirmed={isPurchaseConfirmed}
        authorizationStrategy={authorizationStrategy}
        formattedCellphone={formattedCellphone}
        verificationCode={verificationCode}
        phoneVerificationStep={phoneVerificationStep}
        countDownSeconds={countDownSeconds}
        isCountDownRunning={isCountDownRunning}
        cellphone={cellphone}
        startVerificationProcess={startVerificationProcess}
        handleKeyPressCancel={handleKeyPressCancel}
        confirmPurchaseButtonRef={confirmPurchaseButtonRef}
        cancelPurchase={cancelPurchase}
        smsCodeInputRef={smsCodeInputRef}
        handleKeyPressPhoneVerification={handleKeyPressPhoneVerification}
        onVerificationCodeChange={onVerificationCodeChange}
        onSendVerificationCodeClick={onSendVerificationCodeClick}
        askForVerfication={askForVerfication}
        finishPurchase={finishPurchase}
        smartphoneIMEI={smartphoneIMEI}
        handleKeyPressSmartphoneIMEIVerification={handleKeyPressSmartphoneIMEIVerification}
        onSmartphoneIMEIChange={onSmartphoneIMEIChange}
        needSendSmartphoneImei={needSendSmartphoneImei}
        isPurchaseConfirmationLoading={isPurchaseConfirmationLoading}
        sourceProduct={sourceProduct}
        smartphoneIMEILockConfirmationError={smartphoneIMEILockConfirmationError}
        isLoadingSmartphoneIMEILockConfirmation={isLoadingSmartphoneIMEILockConfirmation}
        smartphoneApiVersion={smartphoneApiVersion}
      />
    </PurschaseOptionsSection>
  )
}

export default PurchaseContractOptions

const PurschaseOptionsSection = styled.div`
  width: 100%;
  background-color: #eff4f0;
  border-radius: 20px;
  justify-content: space-around;

  @media (max-width: ${mobile}) {
    justify-content: center;
  }
`

const PurschaseOptionsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${mobile}) {
    width: 100%;
    flex-wrap: wrap;
  }
`

const PurchaseOptionsCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const PurchaseResumeCardContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
`
