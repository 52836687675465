import { Configurations } from "../redux/reducers/configurations/configurations.reducer"
import { PublicConfigurations } from "../redux/reducers/public-configurations/public-configurations.reducer"

export const isSmartphoneSaleAllowed = (configurations?: Configurations): boolean => {
    return configurations?.smartphoneSale?.isAllowed ?? false
}

export const isTransactionalCreditAllowed = (configurations?: Configurations): boolean => {
    return (configurations?.transactionalCredit?.isAllowed ?? false) &&
        !isSmartphoneSaleAllowed(configurations)
}

export const isServiceCentralAllowed = (configurations?: Configurations): boolean => { 
    return !isSmartphoneSaleAllowed(configurations) && !isTransactionalCreditAllowed(configurations)
}

export const isPurchaseButtonAllowed = (configurations?: PublicConfigurations): boolean => {
    return !!configurations?.featureFlags.enablePurchaseMenuButton
}

export const isBudgetsButtonAllowed = (configurations?: PublicConfigurations): boolean => {
    return !!configurations?.featureFlags.enableBudgetsMenuButton
}

export const isSSOLoginButtonAllowed = (configurations?: PublicConfigurations): boolean => {
    return !!configurations?.featureFlags.enableSSOLoginButton
}