import { InstallmentValueModifierEntity, InvoiceEntity } from './Entities.dto'

export interface IdRequired {
  id: string
}

export interface IAcceptProposalRequest {
  originationDate?: string
}

export interface IContract {
  id: string
  borrowerPurchaseOrder?: number
  borrowerId: string
  storeId: string
  proposals: IContractProposal[]
  installments: ISingleInstallment[]
  principal: number
  operatorId: string
  createdOn: string
  contractValue?: number
  purchaseType: PurchaseType
  firstInstallmentDueDate: string
  couponId: string
  authorizationStrategy?: AuthorizationStrategy
  couponPrincipalDiscount: number
  budgetId?: number
  // debtFundingPackId: string
  // principalInStore: number
  sourceProduct?: string
  customerFeeRate: number
  customerFeeValue: number
}

export interface IContractProposal {
  id: string
  contractId: string
  // financingOptionId: string
  partnerProposalId: string
  iof: number
  cet: number
  numberOfInstallments: number
  installmentValue: number
  installmentValueWithoutInterest?: number
  iofRate: number
  iofDailyRate: number
  interestRate: number
  defaultInterestRate: number
  defaultFine: number
  wasAccepted: boolean
  couponInstallmentDiscount: number
  dueDateRule?: DueDateRule
  firstInstallmentDueDate: string
}

export enum DueDateRule {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
}

export interface ISingleInstallment {
  id: string
  createdOn: string
  contractId: string
  paymentOperatorId?: string
  paymentStoreId?: string
  installmentOrder: number
  dueDate: string
  installmentValue: number
  paymentTimestamp?: string
  paymentDue: number
  defaultInterest: number
  defaultFine: number
  earlyPaymentDiscount: number
  monetaryCorrection: number
  IOFDiasCorridos: number
  IOFAliquotaFixa: number
  amortization: number
  interest: number
  expectedValueOnDate: number
  paidValue?: number
  isRenegotiation: boolean
  inRenegotiation: boolean
  interestCalculationDate: string
  method: PaymentMethodType
  status: 'PENDING' | 'PAID' | 'PAYMENT_OVERDUE'
  isUpfrontPayment: boolean
  invoices?: ISingleInvoice[]
  couponDiscount: number
  borrowerPurchaseOrder?: number
  numberOfInstallments?: number
  // debtFundingPackId: string
}

export interface IChargeFeeInstallment {
  dueDate: string
  installmentValue: number
  type: InstallmentKindType
  status: InstallmentStatusType
  paymentDue: number
}

export interface ISingleInvoice {
  id: string
  modifiedOn: string
  createdOn: string
  partnerInvoiceId: string
  installments: InstallmentEntity[]
  operatorId: string
  dueDate: string
  paymentDate: string
  liquidationDate: string
  status: string
  barcode: string
  pixQrCodeUrl?: string
  pixCode?: string
  url: string
  paidValue: number
  expectedValue: number
  value: number
  // Partner updates can be: https://dev.iugu.com/docs/referencias-gatilhos or https://dev.iugu.com/reference#buscar-fatura
  partnerUpdates: any
  installmentsValues?: IInstallmentsValues[]
  numberOfInstallments?: number
  simulationDate?: string
  generatedAt?: InvoiceGeneratedAt
  debtFundingPacksSplit?: IDebtFundingPacksSplit[]
  sourceType?: InvoiceSourceType
  // partnerAccountId: string
}

export interface InstallmentEntity {
  dueDate: string
  installmentOrder: number
  paymentTimestamp?: string | undefined
  paidValue?: number | undefined
  method: PaymentMethodType
  isRenegotiation: boolean
  interestCalculationDate: string
  installmentValue: number
  contractId: string
  // financingOptionId: number
  paymentOperatorId?: string | undefined
  paymentStoreId?: string | undefined
  // proposalId: number
  // principalOutstandingBalanceBeforePayment: number
  // principalOutstandingBalanceAfterPayment: number
  defaultInterest: number
  defaultFine: number
  earlyPaymentDiscount: number
  monetaryCorrection: number
  IOFDiasCorridos: number
  IOFAliquotaFixa: number
  interest: number
  amortization: number

  // paymentOperator: Promise<OperatorEntity> | OperatorEntity
  // paymentStore: Promise<StoreEntity> | StoreEntity
  // proposal: Promise<ProposalEntity> | ProposalEntity
  // contract: Promise<ContractEntity> | ContractEntity
  // financingOption: Promise<FinancingOptionEntity> | FinancingOptionEntity
  isUpfrontPayment: boolean
  couponDiscount: number
  // renegotiations: RenegotiatedInstallmentEntity[]
  inRenegotiation: boolean
  invoices?: InvoiceEntity[] | undefined
  modifierId?: number
  modifier?: InstallmentValueModifierEntity
  // debtFundingPack: DebtFundingPackEntity
  // debtFundingPackId: number
}

export interface IInstallmentsValues {
  installmentId: string
  simulatedValue: number
  debtFundingPackId: string
}

export interface IDebtFundingPacksSplit {
  debtFundingPackId: string
  installmentValues?: IInstallmentsValues[]
  splitTotalValue: number
  splitTotalValueInCents: number
}

export interface IInstallmentQueryParams {
  borrowerId?: string
  contractId?: string
  paymentDate?: string
  dueDate?: string
  retailerId?: string
  status?: string
}

export interface IContractPostBody {
  borrowerId: string
  principal: number
  localCashierId: number
  upfrontPayment?: number
  couponId?: string
  firstInstallmentDueDate?: string
  storeId: string
  transactionalCreditEvaluationId?: string
}

export interface IPaymentPostBody {
  storeId: string
  paymentDate?: string
}

export interface IInstallments {
  installments: ISingleInstallment[]
  fees?: IChargeFeeInstallment[]
}

export interface VerifyProposalRequest {
  verificationCode: string
}

export interface InStoreContractsSimulationRequest {
  borrowerId: string
  principal: number
  storeId: string
}

export interface InStoreContractsSimulationResponse {
  storeName?: string
  retailerName?: string
  simulation: InStoreContractsSimulation[]
  expirationTimestamp?: string
}

export interface InStoreContractsSimulation {
  numberOfInstallments: number
  installmentValue: number
  principalValue: number
  totalDebtValue: number
}

export type PurchaseType = 'RECURRING_PURCHASE' | 'BIG_PURCHASE'
export const PURCHASE_TYPES: PurchaseType[] = ['RECURRING_PURCHASE', 'BIG_PURCHASE']

export type PaymentMethodType = 'STORE' | 'INVOICE' | 'RENEGOTIATION'
export const PAYMENT_METHODS_TYPES: PaymentMethodType[] = ['STORE', 'INVOICE', 'RENEGOTIATION']

export const AUTHORIZATION_STRATEGIES = ['BORROWER_SMS', 'ANALYST_VERIFICATION']
export type AuthorizationStrategy = 'BORROWER_SMS' | 'ANALYST_VERIFICATION'

export interface TransactionAuthorizationResponse {
  authorizationStrategy: AuthorizationStrategy
}

export enum InstallmentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  PAYMENT_OVERDUE = 'PAYMENT_OVERDUE',
  CANCELED = 'CANCELED',
}

export type InstallmentStatusType = 'PENDING' | 'PAID' | 'PAYMENT_OVERDUE' | 'CANCELED'

export enum InstallmentKind {
  FEE = 'fee',
  ORIGINATION = 'origination',
}

export type InstallmentKindType = InstallmentKind.FEE | InstallmentKind.ORIGINATION

export enum InvoiceStatus {
  PAID = 'paid',
  PENDING = 'pending',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
}

export type InvoiceStatusType = InvoiceStatus.PAID | InvoiceStatus.PENDING | InvoiceStatus.EXPIRED | InvoiceStatus.CANCELED

export enum InvoiceGeneratedAt {
  MOBILE_APP = 'mobile-app',
  CHATBOT = 'chatbot',
}

export enum SourceProduct {
  SUPER_PURCHASE = 'SUPER_PURCHASE',
  INTEREST_PAID_BY_RETAILER = 'INTEREST_PAID_BY_RETAILER',
  SMARTPHONE_SALE = 'SMARTPHONE_SALE',
}

export enum InvoiceSource {
  SINGLE_INSTALLMENT = 'SINGLE_INSTALLMENT',
  MULTIPLE_INSTALLMENTS = 'MULTIPLE_INSTALLMENTS',
  DETACHED = 'DETACHED',
  RENEGOTIATION = 'RENEGOTIATION',
}

export type InvoiceSourceType = InvoiceSource.SINGLE_INSTALLMENT | InvoiceSource.MULTIPLE_INSTALLMENTS | InvoiceSource.DETACHED | InvoiceSource.RENEGOTIATION
