import * as React from 'react'
import { useEffect, useState } from 'react'
import { NumberFormatValues } from 'react-number-format'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Button } from '../../../atoms/Buttons/Button'
import { CloseButton } from '../../../atoms/Buttons/CloseButton'
import { formatBRL } from '../../../cash-register/common/formatBRL'
import NumberField from '../../../molecules/Application/NumberField'
import { ContractsSimulatorPages } from '../../../pages/ContractsSimulator/ContractsSimulator'
import ContractsSimulatorSliceReducer from '../../../redux/reducers/contracts-simulator/contracts-simulator.reducer'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import analyticsService from '../../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../../services/analytics/events'
import { black, gray, green, red, white } from '../../../ui/utils/_colors'

export const ContractsSimulatorForm: React.FC = () => {
  const { simulationValue, simulationParams, analyticsEventPrefix } = useTypedSelector(state => ({
    simulationValue: state.contractsSimulator.simulationValue,
    simulationParams: state.contractsSimulator.simulationParams,
    analyticsEventPrefix: state.contractsSimulator.analyticsEventPrefix,
  }))

  const dispatch = useDispatch()

  const handleCloseButton = () => {
    analyticsService.sendEvent(analyticsEventPrefix + AnalyticsEvents.close_simulation)
    dispatch(ContractsSimulatorSliceReducer.actions.setPage(ContractsSimulatorPages.Result))
  }

  const handleSubmitSimulation = () => {
    analyticsService.sendEvent(analyticsEventPrefix + AnalyticsEvents.start_edited_simulation)
    dispatch(ContractsSimulatorSliceReducer.actions.simulateContract({ borrowerId: simulationParams.borrowerId!, principal: value }))
  }

  const [value, setValue] = useState<number>(simulationValue ?? simulationParams.simulationMaxValue!)

  const handleValueChange = (values: NumberFormatValues) => {
    setValue(values.floatValue!)
  }

  const isValidValue = value >= simulationParams.simulationMinValue! && value <= simulationParams.simulationMaxValue!

  const inputErrorMessage =
    value && !isValidValue ? (
      <>
        Digite um valor entre <strong>{formatBRL(simulationParams.simulationMinValue!)}</strong> e <strong>{formatBRL(simulationParams.simulationMaxValue!)}</strong>.
      </>
    ) : (
      'Informe o valor a ser simulado'
    )

  useEffect(() => {
    analyticsService.sendEvent(analyticsEventPrefix + AnalyticsEvents.edit_simulation_screen)
  }, [])

  useEffect(() => {
    if (value && !isValidValue) {
      analyticsService.sendEvent(analyticsEventPrefix + AnalyticsEvents.simulation_invalid_values)
    }
  }, [!isValidValue])

  return (
    <>
      <MainContainer>
        <CloseButtonContainer>
          <CloseButton onCloseButtonClick={handleCloseButton} />
        </CloseButtonContainer>

        <Title>Simular parcelamento</Title>

        <LimitContainer>
          <BoldText>Valor total disponível para comprar</BoldText>
          <LimitValue>{formatBRL(simulationParams.simulationMaxValue!)}</LimitValue>
        </LimitContainer>

        <FormField
          label={'Valor da compra'}
          value={value}
          prefix="R$ "
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          onValueChange={handleValueChange}
          error={!isValidValue}
        />
        <InputErrorText shouldRender={!isValidValue}>{inputErrorMessage}</InputErrorText>
      </MainContainer>

      <Footer>
        <Button text={'Simular parcelamento'} textColor={white.primary} backgroundColor={green.dark2} disabled={!isValidValue} onClick={handleSubmitSimulation} />
      </Footer>
    </>
  )
}

interface StyleProps {
  shouldRender?: boolean
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: auto;
  width: 100%;
  padding: 24px 24px 44vh 24px;
`

const CloseButtonContainer = styled.div`
  padding-bottom: 16px;
`

const Title = styled.text`
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  color: ${black.primary};
  text-align: left;
  line-height: 24px;
`

const LimitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 16px 0px;
`

const BoldText = styled.text`
  font-family: Inter;
  font-size: 12px;
  color: ${gray.scale700};
  font-weight: 600;
  line-height: 16px;
  text-align: left;
`

const LimitValue = styled.text`
  font-family: Inter;
  font-size: 20px;
  color: ${black.primary};
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
`

const FormField = styled(NumberField).attrs({
  type: 'tel',
  mask: '_',
  style: { width: '100%', marginTop: '16px' },
  autoFocus: true,
})``

const InputErrorText = styled.text<StyleProps>`
  margin-top: 8px;
  visibility: ${(props: StyleProps) => (props.shouldRender ? 'visible' : 'hidden')};

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${red.rubi};
`

const Footer = styled.div`
  width: 100%;
  padding: 0px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  bottom: 16px;
`
