import { Switch } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { red } from '../../ui/utils/_colors'

interface PermissionSwitchProps {
  permission: PermissionState | null
  name: string
  onClick: () => void
}

const PermissionSwitch: React.FC<PermissionSwitchProps> = ({ permission, name, onClick }) => {
  const color = 'primary'

  const renderSwitch = (checked: boolean, showWarning: boolean) => (
    <div onClick={!checked && permission !== 'granted' ? onClick : undefined}>
      <div>
        <Switch checked={checked} name={name} color={color} />
        <span>{name}</span>
      </div>
      {showWarning && <WarningText>Essa permissão foi negada. Por favor, acesse as configurações do navegador para concedê-la.</WarningText>}
    </div>
  )

  switch (permission) {
    case 'prompt':
      return renderSwitch(false, false)
    case 'granted':
      return renderSwitch(true, false)
    case 'denied':
      return renderSwitch(false, true)
    default:
      return null
  }
}

export default PermissionSwitch

const WarningText = styled.span`
  color: ${red.rubi};
  justify-text: center;
`
