import axios, { AxiosInstance } from "axios";
import { PublicConfigurations } from "../../../redux/reducers/public-configurations/public-configurations.reducer";
import { getAxiosInstance } from "../utils";

export default class PublicConfigurationsService {
    private axiosInstance: AxiosInstance = axios.create()

    _init(baseURL: string, token: string | null): void {
        this.axiosInstance = getAxiosInstance({
            baseURL,
            token
        })
    }

    async fetchPublicConfigurations(): Promise<PublicConfigurations> {
        const response = await this.axiosInstance.get<PublicConfigurations>(
            `/configurations/public`, { params: { hostname: window.location.hostname }}
        )
        return response.data
    }
}