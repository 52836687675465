import { TransactionalCreditEvaluation } from '../../transactional-credit/dtos/transactional-credit-evaluation.dto'

export interface CreateSmartphoneSaleCreditEvaluationRequest {
  document: string
  storeId?: string
  retailerId?: string
}

export interface CreateSmartphoneSaleCreditEvaluationResponse {
  evaluation: TransactionalCreditEvaluation
}

export interface SmartphoneSaleIMEIVerifyRequest {
  transactionalCreditEvaluationId: string
  contractId: string
  imei: string
}

export interface SmartphoneSaleImeiVerifyResponse {
    imeiDetails: ImeiDetails
}

export interface ImeiDetails {
    imei: string
    brand: string
    name: string
    apiVersion?: ApiVersion
}

export enum ApiVersion {
  NATIVE = 'NATIVE',
  GENERAL = 'GENERAL',
}

export interface SmartphoneSaleConfirmLockRequest {
  contractId: string
  transactionalCreditEvaluationId: string
}

export interface SmartphoneSaleSubmitLockRequest {
  transactionalCreditEvaluationId: string
  contractId: string
}
