import { createTheme, ThemeProvider } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import { navigateToPath } from '../../common/Navigation'
import { ContractsSimulatorError } from '../../organisms/ContractsSimulator/components/ContractsSimulatorError'
import { ContractsSimulatorForm } from '../../organisms/ContractsSimulator/components/ContractsSimulatorForm'
import { ContractsSimulatorResult } from '../../organisms/ContractsSimulator/components/ContractsSimulatorResult'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import ContractsSimulatorSliceReducer from '../../redux/reducers/contracts-simulator/contracts-simulator.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import analyticsService from '../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../services/analytics/events'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { green } from '../../ui/utils/_colors'

export enum ContractsSimulatorPages {
  Result,
  Form,
  Error,
}

interface ContractsSimulatorProps extends RouteComponentProps {}

const ContractsSimulator: React.FC<ContractsSimulatorProps> = (props: ContractsSimulatorProps) => {
  const { page, simulationParams, simulationValue, simulation, isSimulationLoading, simulationError, analyticsEventPrefix } = useTypedSelector(state => ({
    page: state.contractsSimulator.page,
    simulationParams: state.contractsSimulator.simulationParams,
    simulationValue: state.contractsSimulator.simulationValue,
    simulation: state.contractsSimulator.simulation,
    isSimulationLoading: state.contractsSimulator.isSimulationLoading,
    simulationError: state.contractsSimulator.simulationError,
    analyticsEventPrefix: state.contractsSimulator.analyticsEventPrefix,
  }))

  const dispatch = useDispatch()

  const handleGoBack = () => {
    analyticsService.sendEvent(analyticsEventPrefix + AnalyticsEvents.simulation_go_back)
    props.history.goBack()
  }

  useEffect(() => {
    if (!simulationParams.borrowerId || !simulationParams.simulationMaxValue) {
      return navigateToPath(PagesPathEnum.HOME_PAGE)
    }

    analyticsService.sendEvent(analyticsEventPrefix + AnalyticsEvents.start_simulation)
    dispatch(ContractsSimulatorSliceReducer.actions.simulateContract({ borrowerId: simulationParams.borrowerId, principal: simulationValue ?? simulationParams.simulationMaxValue }))
  }, [])

  useEffect(() => {
    if (simulation) {
      analyticsService.sendEvent(analyticsEventPrefix + AnalyticsEvents.simulation_summary)
      dispatch(ContractsSimulatorSliceReducer.actions.setPage(ContractsSimulatorPages.Result))
    }
    if (simulationError) {
      analyticsService.sendEvent(analyticsEventPrefix + AnalyticsEvents.simulation_error)
      dispatch(ContractsSimulatorSliceReducer.actions.setPage(ContractsSimulatorPages.Error))
    }
  }, [simulation, simulationError])

  const renderPage = () => {
    if (isSimulationLoading) {
      return <LoadingSpinner text="Simulando..." />
    }

    switch (page) {
      case ContractsSimulatorPages.Result:
        return <ContractsSimulatorResult handleGoBack={handleGoBack} />
      case ContractsSimulatorPages.Form:
        return <ContractsSimulatorForm />
      case ContractsSimulatorPages.Error:
        return <ContractsSimulatorError handleGoBack={handleGoBack} />
    }
  }

  return (
    <Container>
      <ThemeProvider theme={theme}>{renderPage()}</ThemeProvider>
    </Container>
  )
}

export default ContractsSimulator

const theme = createTheme({
  palette: {
    primary: {
      main: green.primary300,
    },
  },
})

const Container = styled(PhoneLayout).attrs({
  style: {
    padding: 0,
  },
})``
