// TODO: add all paths as enum to replace the paths string for PagesPathEnum
export enum PagesPathEnum {
  HOME_PAGE = '/',
  LOGIN = '/login',
  BUDGET_PAGE = '/calculadora',
  TRANSACTIONAL_CREDIT_PAGE = '/super-compra',
  APPLICATION = '/applications/:cpf?',
  CASH_REGISTER = '/caixa',
  PURCHASE = '/caixa/compras',
  PURCHASE_CONFIRMATION = '/caixa/compras/:id/confirmacao',
  OPERATOR_SERVICE_CENTER = '/central-de-vendas',
  OPERATOR_CONFIGURATION = '/operador/configuracao',
  UPDATE_CUSTOMER_DATA = '/clientes/alteracao-dados',
  CONTRACT_SIMULATOR = '/simulador-parcelamento',
}
