import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

export type PublicConfigurations = {
  featureFlags: FeatureFlags
  cdn: Assets
  app: AppConfig
}

export type FeatureFlags = {
  enableBudgetsMenuButton: boolean
  enablePurchaseMenuButton: boolean
  enableSSOLoginButton: boolean
}

export type Assets = {
  logoUrl: string
  faviconUrl: string
  logoDarkBackgroundUrl: string
}

export type AppConfig = {
    name: string
}

interface ConfigurationsReducerState {
  isLoadingConfigurations: boolean
  hasFetchedConfigurations: boolean
  configurations: PublicConfigurations
}

const initialState: ConfigurationsReducerState = {
  configurations: {
    featureFlags: {
      enableBudgetsMenuButton: false,
      enablePurchaseMenuButton: false,
      enableSSOLoginButton: false,
    },
    cdn: {
      logoUrl: '',
      faviconUrl: '',
      logoDarkBackgroundUrl: '',
    },
    app: {
      name: ''
    }
  },
  isLoadingConfigurations: false,
  hasFetchedConfigurations: false,
}

export type IFetchConfigurationsPayload = {}

const PublicConfigurationsSliceReducer = createSlice({
  name: 'publicConfigurations',
  initialState,
  reducers: {
    fetchConfigurations: (state, action: PayloadAction<void>) => {
      return { ...state, isLoadingConfigurations: true }
    },
    fetchConfigurationsSuccess: (state, action: PayloadAction<PublicConfigurations>) => {
      return {
        ...state,
        configurations: action.payload,
        isLoadingConfigurations: false,
        hasFetchedConfigurations: true,
      }
    },
    fetchConfigurationsError: (state, action: PayloadAction<AxiosError>) => {
      return { ...state, isLoadingConfigurations: false }
    },
  },
})

export default PublicConfigurationsSliceReducer
