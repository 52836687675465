export enum DocumentErrors {
  INVALID_DOCUMENT = 'Digite um CPF válido.',
}

export enum StoreErrors {
  STORE_CONFIGURATION_REQUIRED = 'Por favor configure uma loja.',
}

export enum PurchasesErrors {
  DEACTIVATED_STORE = 'DEACTIVATED_STORE',
}

export enum PurchasesErrorsMessage {
  DEACTIVATED_STORE = 'Loja inativada. Configure uma loja',
}

export enum PaymentsErrors {
  DEACTIVATED_STORE = 'DEACTIVATED_STORE',
}

export enum PaymentsErrorsMessage {
  DEACTIVATED_STORE = 'Loja inativada. Configure uma loja',
}

export enum OperatorServiceCenterErrors {
  BORROWER_DOES_NOT_HAVE_COMPLETED_APPLICATION = 'BORROWER_DOES_NOT_HAVE_COMPLETED_APPLICATION',
}

export enum UnmappedErrorMessages {
  UNEXPECTED_ERROR = 'Erro inesperado, por favor, tente novamente.',
}

export const operatorsErrorResponse: { [err: string]: string } = {
  OPERATOR_NOT_FOUND: 'Colaborador não encontrado',
  DATASTORE_ERROR: 'Falha no sistema. Tente novamente',
  OPERATOR_ALREADY_CREATED: 'Colaborador já foi cadastrado',
  MISSING_PHONE_NUMBER: 'Telefone não informado',
  MISSING_CPF: 'CPF não informado',
  MISSING_NAME: 'Nome não informado',
  MISSING_EMAIL: 'Email não informado',
  MISSING_ROLE: 'Função não informada',
  MISSING_STORE_NAME: 'Loja não informada',
  STORE_NOT_FOUND: 'Loja não encontrada',
  ROLE_NOT_FOUND: 'Função não encontrada',
  INVALID_CPF: 'CPF inválido',
  INVALID_PHONE_NUMBER: 'Telefone inválido',
  INVALID_EMAIL: 'Email inválido',
  DUPLICATED_EMAIL: 'Email duplicado na planilha.',
  MISSING_LAST_NAME: 'Nome deve ter sobrenome',
  UNAUTHORIZED_TO_CREATE_ROLE: 'Permissão negada para criar gerente',
}

export const codeVerificationErrorResponse: { [err: string]: string } = {
  ATTEMPT_TO_REASSOCIATE_PHONE: 'O número de telefone já está associado a outro CPF',
  PHONE_CREATION_WITH_NO_OWNER: 'Telefone não associado a um CPF',
  INVALID_CPF: 'CPF inválido',
  INVALID_PHONE_NUMBER_FORMAT: 'Formato de número de telefone inválido',
  INVALID_VERIFICATION_CODE: 'Código de verificação inválido',
  COULD_NOT_SEND_SMS: 'Não foi possível enviar SMS',
  PHONE_SERVICE_UNAVAIBLE: 'Serviço indisponível. Tente mais tarde',
  PHONE_VERIFICATION_SERVICE_UNAVAILABLE: 'Serviço indisponível. Tente mais tarde',
  PHONE_VERIFICATION_NOT_FOUND: 'Verificação de telefone não encontrada. Tente novamente.',
  VERIFICATION_PROCESS_NOT_FOUND: 'Verificação de telefone não encontrada. Tente novamente.',
  PHONE_VERIFICATION_OPERATOR_NOT_FOUND: 'Operador não encontrado .Tente novamente.',
  MISSING_PARAMETERS: 'Tente novamente',
  'Bad Request': 'Código de verificação inválido',
}

export const biometryErrorResponse: { [err: string]: string } = {
  FACE_NEEDS_CENTERING: 'Por favor, centralize seu rosto na moldura',
  FACE_TOO_FAR: 'Por favor, aproxime-se da câmera',
  FACE_TOO_CLOSE: 'Afaste-se da câmera',
  TOO_DARK: 'Iluminação insuficiente',
  OUT_OF_FOCUS: 'Câmera fora de foco',
  FACE_TILTED: 'Por favor, centralize seu rosto na moldura',
  FACE_WITH_GLASSES: 'Por favor, remova seus óculos',
  INCORRECT_DOCUMENT: 'Documento de tipo incorreto ou mal focado',
  INVALID_CPF: 'CPF inválido',
  PERSON_NOT_FOUND: 'CPF não encontrado',
  UNABLE_TOKEN: 'Serviço indisponível. Tente mais tarde',
  PARTNER_ERROR: 'Serviço indisponível. Tente mais tarde',
  CLIENT_NOT_FOUND: 'Cliente não encontrado',
  BIOMETRY_PROCESS_NOT_FOUND: 'Biometria não encontrada. Tente novamente.',
  UNKNOWN_PARTNER_ERROR: 'Falha no sistema. Tente novamente',
  UNKNOWN_DATASTORE_ERROR: 'Falha no sistema. Tente novamente',
  SERVICE_UNAVAIBLE: 'Falha no sistema. Tente novamente',
  DIVERGENCE: 'Fraude detectada',
  NOT_SENT: 'Imagem não enviada. Tente mais tarde',
  IMAGE_TYPE_ALREADY_REGISTERED: 'Este tipo de imagem já foi registrado',
}

export const contractErrorResponse: { [err: string]: string } = {
  INVALID_PURCHASE_VALUE: 'Insira o valor da compra',
  CANT_CREATE_CONTRACT: 'Não foi possível criar o contrato',
  BORROWER_NOT_FOUND: 'CPF não encontrado',
  POLICY_NOT_FOUND: 'Política não encontrada. Tente novamente.',
  CONTRACT_POLICY_NOT_FOUND: 'Política não encontrada. Tente novamente.',
  OPERATOR_IS_NOT_CASHIER: 'Operador não possui permissão para este acesso',
  INSUFFICIENT_LIMIT: 'Limite insuficiente',
  INSUFFICIENT_PURCHASE_VALUE: 'Valor inferior ao limite permitido',
  INSUFFICIENT_FIRST_PURCHASE_VALUE: 'Valor para primeira compra abaixo do mínimo',
  CONTRACT_HAS_INSTALLMENTS_ALREADY: 'Contrato já possui parcelas.',
  INSTALLMENT_NOT_FOUND: 'Parcela não encontrada.',
  PROPOSAL_NOT_FOUND: 'Propostas não encontradas. Tente novamente.',
  UNKNOWN_ERROR: 'Erro desconhecido. Contate o suporte UME.',
  SERVICE_UNAVAIBLE: 'Serviço indisponível. Tente mais tarde',
  ANOTHER_PROPOSAL_ALREADY_ACCEPTED: 'Outra proposta já foi selecionada. Tente novamente.',
  PROPOSAL_REFUSALS_ARE_NOT_SUPPORTED: 'Propostas recusadas não são suportadas. Tente novamente.',
  CONTRACT_NOT_FOUND: 'Contrato não encontrado',
  LIMIT_NOT_FOUND: 'CPF sem limite',
  CONTRACT_HAS_NO_ACCEPTED_PROPOSAL: 'O contrato não possui proposta aceita',
  FINANCIAL_INSTITUCION_COMMUNICATION_ERROR: 'Sistema indisponível. Tente mais tarde',
  OVERDUE_INSTALLMENTS: 'Seu limite foi bloqueado. Você possui parcelas atrasadas.',
  OPEN_RENEGOTIATIONS: 'Seu limite foi bloqueado. Você possui renegociações com parcelas em aberto.',
  MANUALLY_BLOCKED: 'Seu limite foi bloqueado. Entre em contato com a UME.',
  INVALID_ORIGINATION_CODE: 'Código de compra incorreto',
  VERIFICATION_CODE_NOT_FOUND: 'Não foi possível verificar sua proposta com o código informado. Solicite um novo Código para Verificação',
  PROPOSAL_VERIFICATION_CODE_NOT_FOUND: 'Não foi possível verificar sua proposta com o código informado. Solicite um novo Código para Verificação',
  [PurchasesErrors.DEACTIVATED_STORE]: PurchasesErrorsMessage.DEACTIVATED_STORE,
  PURCHASE_DISABLED_FOR_BORROWER: 'Este cliente foi desabilitado para realizar compras. Favor, entrar em contato com a Central de Atendimento para mais detalhes',
  INSUFFICIENT_TRANSACTIONAL_LIMIT: 'Valor abaixo do mínimo da proposta',
  EXCEEDING_TRANSACTIONAL_LIMIT: 'Valor acima do aprovado na proposta',
}

export const creditProfileErrorResponse: { [err: string]: string } = {
  BORROWER_NOT_FOUND: 'CPF não encontrado',
  NO_COMPLETED_PROCESSES_BIOMETRY_FOUND: 'CPF com cadastro incompleto',
  PHONE_NOT_FOUND: 'Telefone não encontrado',
  UNKNOWN_ERROR: 'Erro desconhecido. Contate o suporte UME.',
  LIMIT_NOT_FOUND: 'CPF não encontrado',
  OVERDUE_INSTALLMENTS: 'Seu limite foi bloqueado. Você possui parcelas atrasadas.',
  OPEN_RENEGOTIATIONS: 'Seu limite foi bloqueado. Você possui renegociações com parcelas em aberto.',
  'Bad Request': 'CPF inválido',
}

export enum BorrowerErrors {
  PURCHASE_DISABLED_FOR_BORROWER = 'PURCHASE_DISABLED_FOR_BORROWER',
  BORROWER_NOT_FOUND = 'BORROWER_NOT_FOUND',
}

export const borrowerErrorResponse: { [err: string]: string } = {
  INVALID_PASSWORD: 'Senha inválida',
  BORROWER_NOT_FOUND: 'CPF não encontrado',
  CANT_OVERRIDE_CPF: 'CPF não pode ser sobrescrito.',
  UNKNOWN_DATASTORE_ERROR: 'Erro desconhecido. Contate o suporte UME.',
  PASSWORD_UPDATE_PHONE_VERIFICATION_FAILED: 'Falha ao atualizar telefone. Tente novamente.',
  PASSWORD_UPDATE_PHONE_NOT_FOUND: 'Falha ao atualizar telefone. Tente novamente.',
  PASSWORD_UPDATE_PHONE_NOT_VERIFIED: 'Falha ao atualizar telefone. Tente novamente.',
}

export const installmentPaymentErrorResponse: { [err: string]: string } = {
  PREVIOUS_INSTALLMENT_IS_PENDING: 'Parcela anterior em aberto.',
  ALREADY_PAID: 'Parcela já foi paga.',
  INSTALLMENT_NOT_FOUND: 'Parcela não encontrada.',
  UNKNOWN_ERROR: 'Erro desconhecido. Contate o suporte UME.',
  STORE_NOT_FOUND: 'Loja não encontrada',
  STORE_NOT_ALLOWED_TO_RECEIVE_PAYMENTS: 'A loja selecionada não está habilitada a receber pagamentos.',
  [PaymentsErrors.DEACTIVATED_STORE]: PaymentsErrorsMessage.DEACTIVATED_STORE,
}

export const professionsErrorResponse: { [err: string]: string } = {
  PROFESSION_NOT_FOUND: 'Profissão não encontrada. Tente novamente.',
  GROUP_NOT_FOUND: 'Grupo de profissões não encontrado. Tente novamente.',
  SERVICE_UNAVAIBLE: 'Serviço indisponível. Contate o suporte UME.',
}

export const defaultErrorMessage = 'Ops. Uma falha inesperada aconteceu. Tente novamente.'

export const storeIsNotSetUp = 'Necessário configurar loja para continuar a operação. '
export const ManagerLoginIsRequired = 'Peça ao o gerente responsável para configurar a loja, para que possa concluir seu login.'

export const smartphoneSaleIMEIVerificationErrorResponse: { [err: string]: string } = {
  IMEI_VERIFICATION_SERVICE_UNAVAILABLE: 'Serviço indisponível. Tente novamente em alguns minutos',
  'Bad Request': 'Número IMEI informado é inválido',
}

export const getIMEIVerificationError = (errorCode: string): string => {
  return smartphoneSaleIMEIVerificationErrorResponse[errorCode] || smartphoneSaleIMEIVerificationErrorResponse.IMEI_VERIFICATION_SERVICE_UNAVAILABLE
}
