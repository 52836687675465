import { Button } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history } from '../../../App'
import { formatCellphoneWithoutNinthDigit } from '../../../cash-register/common/formatCellPhone'
import { UME_PHONES } from '../../../config/config'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import ApplicationSliceReducer from '../../../redux/reducers/application/application.reducer'

const BorrowerDisabledToPurchaseAlertMessage = () => {
  return (
    <BorrowerDisabledToPurchaseText>
      Este cliente foi desabilitado para realizar compras temporariamente.
      <br />
      Será necessário atualização cadastral.
      <br />
      {`Solicite ao cliente que entre em contato com nossa central de atendimento pelo WhatsApp ${formatCellphoneWithoutNinthDigit(
        UME_PHONES.customerService.whatsapp
      )}`}
    </BorrowerDisabledToPurchaseText>
  )
}

export const ApplicationBorrowerDisabledToPurchasePage = () => {
  const dispatch = useDispatch()

  const handleReset = () => {
    dispatch(ApplicationSliceReducer.actions.resetState())
    history.push(PagesPathEnum.OPERATOR_SERVICE_CENTER)
  }

  return (
    <Container>
      <BorrowerDisabledToPurchaseAlertMessage />
      <BackButton onClick={handleReset}>Voltar para o cadastro</BackButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 32px 24px;
  justify-content: space-between;

  text-align: left;
  height: 93%;

  // For Desktop
  max-width: 400px;
  margin: auto;
`
const BackButton = styled(Button)`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #ffffff !important;

  // BUTTON
  padding: 20px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 90% !important;

  align-self: center;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`
const BorrowerDisabledToPurchaseText = styled.p`
  color: white;
  text-align: left;
  background-color: #f61e61;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.5rem;
`
