import React from 'react'
import styled from 'styled-components'
import { formatBRL } from '../../cash-register/common/formatBRL'
import { Simulation } from '../../redux/reducers/contracts-simulator/contracts-simulator.reducer'
import { gray } from '../../ui/utils/_colors'

interface SimulationProps {
  simulation: Simulation[]
}

const SimulationList: React.FC<SimulationProps> = ({ simulation }) => {
  return (
    <MainContainer>
      <SimulationUnorderedList>
        {simulation.map((installments, id) => (
          <List key={id}>
            <Text>
              {installments.numberOfInstallments} parcelas de <Value>{formatBRL(installments.installmentValue)}</Value>
            </Text>
          </List>
        ))}
      </SimulationUnorderedList>
    </MainContainer>
  )
}

export default SimulationList

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`

const SimulationUnorderedList = styled.ul`
  padding: 0px;
`

const List = styled.li`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
`

const Text = styled.text`
  color: ${gray.slate};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const Value = styled.text`
  color: ${gray.slate};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`
