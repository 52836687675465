import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { TransactionalCreditPages } from '../../../pages/transactional-credit/TransactionalCreditPages'
import { IBorrowerCreditLimit } from '../../../services/bff/coordinator/dto/Borrowers.dto'
import { AggregatedTransactionalCreditEvaluation, CreateTransactionalCreditEvaluationReq } from '../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'

interface TransactionalCreditState {
  page: TransactionalCreditPages

  transactionalCreditEvaluation?: AggregatedTransactionalCreditEvaluation
  isLoadingTransactionalCreditEvaluation: boolean
  isTransactionalCreditEvaluationError?: boolean

  isFetchingBorrowerLimit: boolean
  availableLimit?: number
}

const initialState: TransactionalCreditState = {
  page: TransactionalCreditPages.Document,

  transactionalCreditEvaluation: undefined,
  isLoadingTransactionalCreditEvaluation: false,
  isTransactionalCreditEvaluationError: false,

  isFetchingBorrowerLimit: false,
  availableLimit: undefined,
}

const TransactionalCreditSliceReducer = createSlice({
  name: 'transactionalCredit',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<TransactionalCreditPages>) => {
      const page = action.payload
      return { ...state, page }
    },

    createTransactionalCreditEvaluation: (state, action: PayloadAction<CreateTransactionalCreditEvaluationReq>) => {
      return { ...state, isLoadingTransactionalCreditEvaluation: true }
    },
    createTransactionalCreditEvaluationSuccess: (state, action: PayloadAction<AggregatedTransactionalCreditEvaluation>) => {
      const transactionalCreditEvaluation = action.payload
      return {
        ...state,
        transactionalCreditEvaluation,
        isLoadingTransactionalCreditEvaluation: false,
      }
    },
    createTransactionalCreditEvaluationError: (state, action: PayloadAction<AxiosError>) => {
      return {
        ...state,
        transactionalCreditEvaluation: undefined,
        isLoadingTransactionalCreditEvaluation: false,
        isTransactionalCreditEvaluationError: true,
      }
    },
    resetTransactionalCreditEvaluation: () => initialState,

    fetchBorrowerLimit: (state, action: PayloadAction<string>) => {
      return { ...state, availableLimit: undefined }
    },
    fetchBorrowerLimitLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isFetchingBorrowerLimit: true }
    },
    fetchBorrowerLimitSuccess: (state, action: PayloadAction<IBorrowerCreditLimit>) => {
      return {
        ...state,
        availableLimit: action.payload.availableLimit,
        isFetchingBorrowerLimit: false,
      }
    },
    fetchBorrowerLimitError: (state, action: PayloadAction<AxiosError>) => {
      return { ...state, isFetchingBorrowerLimit: false }
    },
  },
})

export default TransactionalCreditSliceReducer
