import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CommunicationChannelLabel } from '@scudraservicos/coordinator-client/dist/src/services/applications/interfaces/CommunicationChannel.types'
import { AxiosError } from 'axios'
import moment from 'moment'
import { history, toaster } from '../../../App'
import { insertAt } from '../../../common/ArrayFunctions'
import { APPLICATION_PROFESSIONS, IApplicationProfession } from '../../../organisms/Application/ApplicationSteps/application-professions'
import { APPLICATION_PAGES } from '../../../organisms/Application/ApplicationSteps/application-utils'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import { PreCreditEvaluationResponse } from '../../../services/bff/borrowers/dto/Borrower.dto'
import { ApplicationCoupon, BorrowerData } from '../../../services/bff/coordinator/dto/Borrowers.dto'
import { TransactionalCreditEvaluation } from '../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { TransactionalCreditEvaluationResult } from '../../../services/bff/transactional-credit/enums/transactional-credit-evaluation-results.enum'
import { TransactionalCreditEvaluationStatus } from '../../../services/bff/transactional-credit/enums/transactional-credit-evaluation-status.enum'
import { ApplicationHelper } from './appication.helper'
import { transformHttpApplicationEngineApiErrorsToString, transformHttpBorrowersApiErrorsToString } from './application.errors'
import {
  ApplicationVersion,
  APPLICATION_STATUS,
  BiometryStatus,
  BorrowerWithStatus,
  IApplicationFormField,
  IPreApplicationResponse,
  PRE_APPLICATION_STATUS,
  removeCountryDigitsFromNullablePhoneNumber,
  removeCountryDigitsFromPhoneNumber,
} from './application.types'

interface IApplicationReducerState {
  page: number
  pagesOrder: APPLICATION_PAGES[]

  borrower?: BorrowerWithStatus
  borrowerId?: string
  cpf: string

  isLoadingPreApplication: boolean
  preApplicationResponse?: IPreApplicationResponse

  phoneNumber: string
  hasSendPhoneNumber: boolean
  hasSendVerificationCode: boolean
  isSubmittingPhoneNumber: boolean
  lastVerificationCodeSentTimestamp?: Date

  secondaryPhoneNumber: string
  secondaryPhoneNumbersArray: string[]
  secondaryPhoneNumbersLoadingArray: boolean[]
  isSubmittingSecondaryPhoneNumber: boolean

  isPhoneModalVisible: boolean
  isPhoneConfirmationModalVisible: boolean
  isAuthorizationByManagerModalVisible: boolean
  isResendingVerificationCode: boolean
  verificationCode: string
  isVerifyingVerificationCode: boolean
  isPhoneVerified: boolean

  numberOfSmsSent: number

  name: string
  birthDate: string
  profession?: IApplicationProfession
  email: string
  cep: string
  hasBorrowerFormUpdated: boolean
  isUpdatingBorrower: boolean

  isPoolingApplication: boolean
  isFetchingBorrowerLimit: boolean
  borrowerLimit?: number
  availableLimit?: number

  applicationStartTimestamp?: Date

  isCommunicationChannelSelected: boolean

  selectedCommunicationChannel: CommunicationChannelLabel

  isResendingCode: boolean

  billDueDay?: number
  isUpdatingBorrowerBillDueDay: boolean
  hasBorrowerBillDueDay: boolean
  isDueDaySet: boolean
  applicationCoupon?: ApplicationCoupon

  transactionalCreditEvaluation?: TransactionalCreditEvaluation
  isFetchingTransactionalCreditEvaluation: boolean
}

const BORROWER_FORM_FIELDS: IApplicationFormField[] = ['name', 'cep', 'birthDate', 'email', 'profession']

const initialState: IApplicationReducerState = {
  page: 0,
  // pagesOrder: [APPLICATION_PAGES.DENIED_PAGE],
  pagesOrder: [APPLICATION_PAGES.CPF_PAGE],

  borrower: undefined,
  borrowerId: undefined,
  // borrowerId: '8566150',
  cpf: '',

  isLoadingPreApplication: false,
  // preApplicationResponse: { status: PRE_APPLICATION_STATUS.APPROVED, reason: '', limit: 500 },
  // preApplicationResponse: { status: PRE_APPLICATION_STATUS.DENIED, reason: '', limit: 500 },
  preApplicationResponse: undefined,

  phoneNumber: '',
  hasSendPhoneNumber: false,
  // phoneNumber: '92982233038',
  hasSendVerificationCode: false,
  // hasSendVerificationCode: false,
  isSubmittingPhoneNumber: false,
  lastVerificationCodeSentTimestamp: undefined,

  secondaryPhoneNumber: '',
  secondaryPhoneNumbersArray: [],
  secondaryPhoneNumbersLoadingArray: [],
  isSubmittingSecondaryPhoneNumber: false,

  isPhoneModalVisible: false,
  isPhoneConfirmationModalVisible: false,
  isAuthorizationByManagerModalVisible: false,
  isResendingVerificationCode: false,
  verificationCode: '',
  isVerifyingVerificationCode: false,
  isPhoneVerified: false,

  name: '',
  birthDate: '',
  profession: undefined,
  // profession: { id: 641, name: 'ABCDARIO' },
  email: '',
  cep: '',
  // cep: '69050001',
  hasBorrowerFormUpdated: false,
  isUpdatingBorrower: false,

  numberOfSmsSent: 0,

  isPoolingApplication: false,
  isFetchingBorrowerLimit: false,
  borrowerLimit: undefined,
  availableLimit: undefined,

  applicationStartTimestamp: undefined,
  // applicationStartTimestamp: new Date('2020-12-27 13:49:43.399000')

  isCommunicationChannelSelected: false,

  selectedCommunicationChannel: CommunicationChannelLabel.NO_CHANNEL,

  isResendingCode: false,

  billDueDay: undefined,
  isUpdatingBorrowerBillDueDay: false,
  hasBorrowerBillDueDay: false,
  isDueDaySet: false,
  applicationCoupon: undefined,

  transactionalCreditEvaluation: undefined,
  isFetchingTransactionalCreditEvaluation: false,
}

// --- Payload types
export type IOnApplicationFormValueChangePayload = {
  formField: IApplicationFormField
  formValue: any
}

export type IStartPreApplicationPayload = {
  cpf: string
}

export type IUpdateBorrowerBillDueDayPayload = {
  billDueDay: number
}

export type IPhoneNumberPayload = {
  phoneNumber: string
}

export type ISendVerificationCode = {
  phoneNumber: string
}

export type ICommunicationChannelSelection = {
  channelIndex: string
}

export type IInsertSecondaryPhone = {
  phoneNumber: string
}

export type IDeleteSecondaryPhone = {
  index: number
  phoneNumber: string
}

export type IVerifyVerificationCode = {
  verificationCode: string
}

export type IOnBorrowerFormSubmit = {
  name: string
  birthDate: string
  // profession: IApplicationProfession
  cep: string
  email: string
}

export type IfetchApplicationStatusSuccess = {
  applicationStatus: APPLICATION_STATUS
  applicationVersion: ApplicationVersion
  biometryStatus?: BiometryStatus
}

export type FetchTransactionalCreditEvaluation = {
  cpf: string
  storeId?: string
  retailerId?: string
  status: TransactionalCreditEvaluationStatus
  results: TransactionalCreditEvaluationResult[]
}

// Payload pattern - https://github.com/redux-utilities/flux-standard-action

const ApplicationSliceReducer = createSlice({
  name: 'application',
  initialState,
  reducers: {
    onFormValueChange: (state, action: PayloadAction<IOnApplicationFormValueChangePayload>) => {
      const { formField, formValue } = action.payload

      if (BORROWER_FORM_FIELDS.includes(formField)) {
        return { ...state, [formField]: formValue, hasBorrowerFormUpdated: true }
      }

      return { ...state, [formField]: formValue }
    },
    onBorrowerFormSubmit: (state, action: PayloadAction<IOnBorrowerFormSubmit>) => {
      const { name, birthDate, cep, email } = action.payload
      return { ...state, name, birthDate, cep, email }
    },
    nextPage: (state, action: PayloadAction<undefined>) => {
      const { page } = state

      return { ...state, page: page + 1 }
    },
    previousPage: (state, action: PayloadAction<undefined>) => {
      const { page } = state

      if (page !== APPLICATION_PAGES.CPF_PAGE) {
        // Reset state
        if (page - 1 === APPLICATION_PAGES.CPF_PAGE) {
          history.push(PagesPathEnum.OPERATOR_SERVICE_CENTER)
          return { ...initialState }
        }

        return { ...state, page: page - 1 }
      }

      return state
    },
    fetchPreApplication: (state, action: PayloadAction<IStartPreApplicationPayload>) => {
      // Used on Redux-Sagas
      return state
    },
    fetchPreApplicationLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isLoadingPreApplication: true }
    },
    fetchPreApplicationSuccess: (state, action: PayloadAction<IPreApplicationResponse>) => {
      let payload = action.payload
      let newState: IApplicationReducerState = {
        ...initialState,
        preApplicationResponse: payload,
      }

      const borrower = {
        ...payload.borrower,
        unverifiedPhoneNumber: removeCountryDigitsFromNullablePhoneNumber(payload.borrower.unverifiedPhoneNumber),
      }
      let { name, birthDate, cep, email, professionId, billDueDay } = borrower
      const profession = APPLICATION_PROFESSIONS.find(profession => profession.id === Number(professionId))
      if (birthDate) {
        birthDate = moment(birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
      } else {
        birthDate = ''
      }

      newState = {
        ...newState,
        borrower,
        borrowerId: borrower.id,
        cpf: borrower.cpf,
        name: name ? name : '',
        birthDate,
        cep: cep ? cep : '',
        email: email ? email : '',
        profession,
        hasBorrowerBillDueDay: Boolean(billDueDay),
      }

      if (borrower.phones && borrower.phones.length > 0) {
        const secondaryPhoneNumbersArray = borrower.phones.map((phone: any) => removeCountryDigitsFromPhoneNumber(phone.phoneNumber)) as string[]
        const secondaryPhoneNumbersLoadingArray = new Array<boolean>(secondaryPhoneNumbersArray.length).fill(false)

        newState = {
          ...newState,
          secondaryPhoneNumbersArray,
          secondaryPhoneNumbersLoadingArray,
        }
      }

      if (borrower.phoneNumber) {
        newState = {
          ...newState,
          borrower,
          phoneNumber: removeCountryDigitsFromPhoneNumber(borrower.phoneNumber),
          isPhoneVerified: true,
          hasSendVerificationCode: true,
          hasSendPhoneNumber: true,
        }
      }

      const pagesOrder = ApplicationHelper.definePagesOrderFromBorrowerInfo(
        borrower.borrowerStatus,
        borrower.phoneStatus,
        borrower.enableHighRecurrence,
        payload.applicationVersion,
        Boolean(borrower.billDueDay),
        payload.status,
        payload.biometryStatus,
        borrower.borrowerSettings?.disablePurchase,
        payload.config
      )
      newState = { ...newState, page: 1, pagesOrder }

      if (payload.application) {
        newState = { ...newState, applicationStartTimestamp: new Date(payload.application.createdOn) }
      }

      return { ...newState, isLoadingPreApplication: false }
    },
    fetchPreApplicationError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload

      return { ...state, isLoadingPreApplication: false }
    },
    sendVerificationCode: (state, action: PayloadAction<ISendVerificationCode>) => {
      // Used on Redux-Sagas
      const isResendingCode = false

      return { ...state, isResendingCode }
    },
    sendPrimaryPhoneNumber: (state, action: PayloadAction<ISendVerificationCode>) => {
      return { ...state, isSubmittingPhoneNumber: true, isResendingCode: false }
    },
    sendPhoneSuccess: (state, action: PayloadAction<IPhoneNumberPayload>) => {
      const { phoneNumber } = action.payload

      return {
        ...state,
        hasSendPhoneNumber: true,
        phoneNumber,
        isSubmittingPhoneNumber: false,
      }
    },
    sendPhoneError: (state, action: PayloadAction<AxiosError>) => {
      // Used on Redux-Sagas
      const error = action.payload

      const message = transformHttpBorrowersApiErrorsToString(error)
      toaster.showErrorToast(message)
      return { ...state, isSubmittingPhoneNumber: false }
    },
    makeAbleResendVerificationCode: (state, action: PayloadAction<boolean>) => {
      const isResendingCode = action.payload

      return { ...state, isResendingCode }
    },
    selectCommunicationChannel: (state, action: PayloadAction<ICommunicationChannelSelection>) => {
      const isCommunicationChannelSelected = action.payload.channelIndex !== 'default' ? true : false

      let channel: CommunicationChannelLabel
      switch (action.payload.channelIndex) {
        case '0':
          channel = CommunicationChannelLabel.SMS
          break
        case '1':
          channel = CommunicationChannelLabel.WHATSAPP
          break
        case '2':
          channel = CommunicationChannelLabel.PHONE_CALL
          break
        default:
          channel = CommunicationChannelLabel.NO_CHANNEL
          break
      }

      return { ...state, isCommunicationChannelSelected, selectedCommunicationChannel: channel }
    },
    sendVerificationCodeLoading: (state, action: PayloadAction<undefined>) => {
      return {
        ...state,
        isSubmittingPhoneNumber: true,
      }
    },
    sendVerificationCodeSuccess: (state, action: PayloadAction<ISendVerificationCode>) => {
      const { phoneNumber } = action.payload
      const { numberOfSmsSent } = state
      toaster.showSuccessToast(`Código de verificação enviado`)

      return {
        ...state,
        hasSendVerificationCode: true,
        hasSendPhoneNumber: true,
        phoneNumber,
        lastVerificationCodeSentTimestamp: new Date(),
        numberOfSmsSent: numberOfSmsSent + 1,
        isSubmittingPhoneNumber: false,
      }
    },
    sendVerificationCodeError: (state, action: PayloadAction<AxiosError>) => {
      // Used on Redux-Sagas
      const error = action.payload

      const message = transformHttpBorrowersApiErrorsToString(error)
      toaster.showErrorToast(message)
      return { ...state, isSubmittingPhoneNumber: false }
    },
    insertSecondaryPhone: (state, action: PayloadAction<IInsertSecondaryPhone>) => {
      // Used on Redux-Sagas
      return state
    },
    insertSecondaryPhoneLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isSubmittingSecondaryPhoneNumber: true }
    },
    insertSecondaryPhoneSuccess: (state, action: PayloadAction<IInsertSecondaryPhone>) => {
      const { phoneNumber } = action.payload
      const secondaryPhoneNumbersArray = Object.assign([], state.secondaryPhoneNumbersArray)
      const secondaryPhoneNumbersLoadingArray = Object.assign([], state.secondaryPhoneNumbersLoadingArray)

      secondaryPhoneNumbersArray.push(phoneNumber)
      secondaryPhoneNumbersLoadingArray.push(false)

      return {
        ...state,
        secondaryPhoneNumbersArray,
        secondaryPhoneNumbersLoadingArray,
        secondaryPhoneNumber: '',
        isSubmittingSecondaryPhoneNumber: false,
      }
    },
    insertSecondaryPhoneError: (state, action: PayloadAction<AxiosError>) => {
      // Used on Redux-Sagas
      const error = action.payload

      const message = transformHttpBorrowersApiErrorsToString(error)
      toaster.showErrorToast(message)
      return { ...state, isSubmittingSecondaryPhoneNumber: false }
    },
    deleteSecondaryPhone: (state, action: PayloadAction<IDeleteSecondaryPhone>) => {
      // Used on Redux-Sagas
      return state
    },
    deleteSecondaryPhoneLoading: (state, action: PayloadAction<IDeleteSecondaryPhone>) => {
      const { index } = action.payload
      let { secondaryPhoneNumbersLoadingArray } = state

      secondaryPhoneNumbersLoadingArray = Object.assign([], secondaryPhoneNumbersLoadingArray)
      secondaryPhoneNumbersLoadingArray[index] = true

      return { ...state, secondaryPhoneNumbersLoadingArray }
    },
    deleteSecondaryPhoneSuccess: (state, action: PayloadAction<IDeleteSecondaryPhone>) => {
      const { index } = action.payload

      const secondaryPhoneNumbersArray = Object.assign([], state.secondaryPhoneNumbersArray)
      const secondaryPhoneNumbersLoadingArray = Object.assign([], state.secondaryPhoneNumbersLoadingArray)

      secondaryPhoneNumbersArray.splice(index, 1)
      secondaryPhoneNumbersLoadingArray.splice(index, 1)

      return { ...state, secondaryPhoneNumbersArray, secondaryPhoneNumbersLoadingArray }
    },
    deleteSecondaryPhoneError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload

      const message = transformHttpBorrowersApiErrorsToString(error)
      toaster.showErrorToast(message)
      return { ...state }
    },
    phoneModalAction: (state, action: PayloadAction<boolean>) => {
      const isPhoneModalVisible = action.payload

      return { ...state, isPhoneModalVisible }
    },
    setPhoneConfirmationModal: (state, action: PayloadAction<boolean>) => {
      return { ...state, isPhoneConfirmationModalVisible: action.payload }
    },
    verifyVerificationCode: (state, action: PayloadAction<IVerifyVerificationCode>) => {
      // REDUX SAGA
      const { verificationCode } = action.payload
      return { ...state, verificationCode }
    },
    verifyVerificationCodeLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isVerifyingVerificationCode: true }
    },
    verifyVerificationCodeSuccess: (state, action: PayloadAction<PreCreditEvaluationResponse>) => {
      toaster.showSuccessToast(`Telefone verificado com sucesso!`)

      if (action.payload?.status === PRE_APPLICATION_STATUS.PRE_APPLICATION_DENIED) {
        const pagesOrder = [APPLICATION_PAGES.CPF_PAGE, state.pagesOrder[state.page], APPLICATION_PAGES.PRE_DENIED_PAGE]
        const page = 1

        return {
          ...state,
          isPhoneVerified: true,
          secondsToSendSMS: 0,
          isVerifyingVerificationCode: false,
          pagesOrder,
          page,
        }
      } else if (action.payload?.status === PRE_APPLICATION_STATUS.PRE_APPLICATION_APPROVED) {
        const pagesOrder = insertAt(state.pagesOrder, 2, APPLICATION_PAGES.PRE_APPROVED_PAGE)

        return {
          ...state,
          isPhoneVerified: true,
          secondsToSendSMS: 0,
          isVerifyingVerificationCode: false,
          pagesOrder,
        }
      }
      return {
        ...state,
        isPhoneVerified: true,
        secondsToSendSMS: 0,
        isVerifyingVerificationCode: false,
      }
    },
    verifyVerificationCodeError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload

      const message = transformHttpBorrowersApiErrorsToString(error)
      toaster.showErrorToast(message)
      return { ...state, isPhoneVerified: false, verificationCode: '', isVerifyingVerificationCode: false }
    },
    updateBorrower: (state, action: PayloadAction<undefined>) => {
      // REDUX SAGA
      return state
    },
    updateBorrowerLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isUpdatingBorrower: true }
    },
    updateBorrowerSuccess: (state, action: PayloadAction<BorrowerWithStatus>) => {
      const borrower = action.payload
      const { page } = state

      return {
        ...state,
        borrower: { ...borrower },
        page: page + 1,
        hasBorrowerFormUpdated: false,
        isUpdatingBorrower: false,
      }
    },
    updateBorrowerError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload

      const message = transformHttpBorrowersApiErrorsToString(error)
      toaster.showErrorToast(message)
      return { ...state, isUpdatingBorrower: false }
    },

    updateBorrowerBillDueDay: (state, action: PayloadAction<undefined | object>) => {
      return { ...state, isDueDaySet: false }
    },
    updateBorrowerBillDueDayLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isUpdatingBorrowerBillDueDay: true }
    },

    updateBorrowerBillDueDaySuccess: (state, action: PayloadAction<BorrowerWithStatus>) => {
      const borrower = action.payload

      return {
        ...state,
        borrower: { ...borrower },
        isUpdatingBorrowerBillDueDay: false,
        isDueDaySet: true,
      }
    },

    updateBorrowerBillDueDayError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      const message = transformHttpBorrowersApiErrorsToString(error)
      toaster.showErrorToast(message)

      return { ...state, isUpdatingBorrowerBillDueDay: false }
    },

    resetState: (state, action: PayloadAction<undefined>) => {
      return { ...initialState }
    },
    startApplication: (state, action: PayloadAction<undefined>) => {
      // REDUX SAGA
      const { page } = state
      return { ...state, page: page + 1 }
    },
    startApplicationLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isPoolingApplication: true }
    },
    restartApplicationLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isPoolingApplication: false }
    },
    startApplicationSuccess: (state, action: PayloadAction<undefined>) => {
      return { ...state, applicationStartTimestamp: new Date() }
    },
    startApplicationError: (state, action: PayloadAction<AxiosError>) => {
      return { ...state, applicationStartTimestamp: undefined }
    },
    fetchApplicationStatusError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload

      const message = transformHttpApplicationEngineApiErrorsToString(error)
      toaster.showErrorToast(message)
      return state
    },
    fetchApplicationStatusSuccess: (state, action: PayloadAction<IfetchApplicationStatusSuccess>) => {
      const { applicationStatus, applicationVersion, biometryStatus } = action.payload
      let pagesOrder = ApplicationHelper.definePagesOrderFromApplicationInfo(
        applicationStatus,
        state.isPhoneVerified,
        state.hasBorrowerBillDueDay,
        applicationVersion,
        biometryStatus,
        state.borrower?.enableHighRecurrence,
        state.borrower?.borrowerSettings?.disablePurchase
      )
      return { ...state, page: 0, pagesOrder }
    },
    fetchBorrowerLimit: (state, action: PayloadAction<undefined>) => {
      // REDUX SAGA
      return { ...state, borrowerLimit: undefined, availableLimit: undefined, applicationCoupon: undefined }
    },
    fetchBorrowerLimitLoading: (state, action: PayloadAction<undefined>) => {
      return { ...state, isFetchingBorrowerLimit: true }
    },
    fetchBorrowerLimitSuccess: (state, action: PayloadAction<BorrowerData>) => {
      const borrowerLimit = action.payload.borrowerCreditLimit.totalLimit
      const availableLimit = action.payload.borrowerCreditLimit.availableLimit
      const applicationCoupon = action.payload.applicationCoupon

      return {
        ...state,
        borrowerLimit,
        availableLimit,
        applicationCoupon,
        isFetchingBorrowerLimit: false,
      }
    },
    fetchBorrowerLimitError: (state, action: PayloadAction<AxiosError>) => {
      return { ...state, isFetchingBorrowerLimit: false }
    },

    fetchTransactionalCreditEvaluation: (state, action: PayloadAction<FetchTransactionalCreditEvaluation>) => {
      return { ...state, transactionalCreditEvaluation: undefined }
    },
    fetchTransactionalCreditEvaluationLoading: (state) => {
      return { ...state, isFetchingTransactionalCreditEvaluation: true }
    },
    fetchTransactionalCreditEvaluationSuccess: (state, action: PayloadAction<TransactionalCreditEvaluation>) => {
      const evaluation = action.payload
      return {
        ...state,
        transactionalCreditEvaluation: evaluation,
        isFetchingTransactionalCreditEvaluation: false,
      }
    },
    fetchTransactionalCreditEvaluationError: (state, action: PayloadAction<AxiosError>) => {
      return { ...state, isFetchingTransactionalCreditEvaluation: false }
    },
  },
})

export default ApplicationSliceReducer
