import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { toaster } from '../../../App'
import { bffParceirosApiService } from '../../../services/bff'
import { IBorrowerCreditLimit } from '../../../services/bff/coordinator/dto/Borrowers.dto'
import { CreateTransactionalCreditEvaluationReq, CreateTransactionalCreditEvaluationResponse } from '../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import TransactionalCreditSliceReducer from './tx-credit.reducer'

function* createTransactionalCreditEvaluation(action: PayloadAction<CreateTransactionalCreditEvaluationReq>) {
  try {
    const document = action.payload.document
    const storeId = action.payload.storeId
    const requestedAmount = action.payload.requestedAmount
    const operatorPhoneNumber = action.payload.operatorPhoneNumber

    if (!storeId) {
      return toaster.showErrorToast(`Loja não configurada`)
    }

    const response: CreateTransactionalCreditEvaluationResponse = yield bffParceirosApiService.transactionalCredit.createEvaluation({ document, storeId, requestedAmount, operatorPhoneNumber })

    yield put(TransactionalCreditSliceReducer.actions.createTransactionalCreditEvaluationSuccess(response?.transactionalCreditEvaluation))
  } catch (error) {
    yield put(TransactionalCreditSliceReducer.actions.createTransactionalCreditEvaluationError(error))
  }
}

function* fetchBorrowerLimit(action: PayloadAction<string>) {
  try {
    const borrowerId = action.payload
    const store = bffParceirosApiService.coordinator.getStore()
    const storeId = store?.id

    if (!storeId) {
      return toaster.showErrorToast(`Loja não configurada`)
    }

    yield put(TransactionalCreditSliceReducer.actions.fetchBorrowerLimitLoading())

    const response: IBorrowerCreditLimit = yield bffParceirosApiService.coordinator.fetchBorrowerLimitForStore(
      borrowerId,
      storeId,
    )
    yield put(TransactionalCreditSliceReducer.actions.fetchBorrowerLimitSuccess(response))
  } catch (error) {
    yield put(TransactionalCreditSliceReducer.actions.fetchBorrowerLimitError(error))
  }
}

const transactionalCreditSaga = [
  takeLatest(TransactionalCreditSliceReducer.actions.createTransactionalCreditEvaluation, createTransactionalCreditEvaluation),
  takeLatest(TransactionalCreditSliceReducer.actions.fetchBorrowerLimit, fetchBorrowerLimit),
]

export default transactionalCreditSaga
