import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { ContractsSimulatorPages } from '../../../pages/ContractsSimulator/ContractsSimulator'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'

interface ContractsSimulatorState {
  page?: ContractsSimulatorPages
  simulationParams: SimulationParams
  simulationValue?: number
  simulation?: ContractSimulation
  isSimulationLoading: boolean
  simulationError?: AxiosError
  analyticsEventPrefix?: string
}

export interface SimulationParams {
  borrowerId?: string
  simulationMinValue?: number
  simulationMaxValue?: number
  simulationFlowStartedFrom?: PagesPathEnum
}

export interface SimulateContract {
  borrowerId: string
  principal: number
}

export interface ContractSimulation {
  storeName?: string
  retailerName?: string
  simulation: Simulation[]
  expirationTimestamp?: string
}

export interface Simulation {
  numberOfInstallments: number
  installmentValue: number
  principalValue: number
  totalDebtValue: number
}

const initialState: ContractsSimulatorState = {
  page: undefined,
  simulationParams: {
    borrowerId: undefined,
    simulationMinValue: undefined,
    simulationMaxValue: undefined,
    simulationFlowStartedFrom: undefined,
  },
  simulationValue: undefined,
  simulation: undefined,
  isSimulationLoading: false,
  simulationError: undefined,
  analyticsEventPrefix: undefined,
}

const ContractsSimulatorSliceReducer = createSlice({
  name: 'contractsSimulator',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<ContractsSimulatorPages>) => {
      const page = action.payload
      return { ...state, page }
    },
    setSimulationParams: (state, action: PayloadAction<SimulationParams>) => {
      const { simulationFlowStartedFrom } = action.payload

      const analyticsEventPrefixMap: Record<string, string> = {
        [PagesPathEnum.OPERATOR_SERVICE_CENTER]: 'cv_',
        [PagesPathEnum.TRANSACTIONAL_CREDIT_PAGE]: 'sp_',
        [PagesPathEnum.APPLICATION]: 'appl_',
      }

      const analyticsEventPrefix = simulationFlowStartedFrom ? analyticsEventPrefixMap[simulationFlowStartedFrom] : state.analyticsEventPrefix

      return {
        ...initialState,
        simulationParams: action.payload,
        analyticsEventPrefix,
      }
    },
    simulateContract: (state, action: PayloadAction<SimulateContract>) => {
      const { principal } = action.payload
      return {
        ...state,
        isSimulationLoading: true,
        simulationValue: principal,
      }
    },
    simulateContractSuccess: (state, action: PayloadAction<ContractSimulation>) => {
      return {
        ...state,
        simulation: action.payload,
        isSimulationLoading: false,
      }
    },
    simulateContractError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      return {
        ...state,
        simulation: undefined,
        simulationError: error,
        isSimulationLoading: false,
      }
    },
  },
})

export default ContractsSimulatorSliceReducer
