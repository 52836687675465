import { Button, Card, Elevation, HTMLSelect } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import LocalStorageWrapper, { LocalStorageItem } from '../../common/assets/utils/LocalStorageWrapper'
import { Role } from '../../common/constants'
import { storeIsNotSetUp } from '../../common/ErrorStrings'
import { Loading } from '../../legacy-lib/design-kit-ui'
import { StoreSelection } from '../../molecules/StoreSelection/StoreSelection'
import { OperatorCenterHelper } from '../../pages/Operators/OperatorCenterHelper'
import ConfigurationsSliceReducer from '../../redux/reducers/configurations/configurations.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { bffParceirosApiService } from '../../services/bff'
import { StoreDto } from '../../services/bff/retailers/dto/Store.dto'
import { CaptureMethodConfig, CaptureMethodService, CaptureMethodUIConfig } from '../../services/captureMethodService/CaptureMethodService'
import { formItemsContainerStyle } from './style'

export interface IStoreConfigurationState {
  stores: Map<string, StoreDto>
  selectedStore?: StoreDto

  selectedCaptureMethod?: CaptureMethodConfig
  allowedCaptureMethods?: CaptureMethodUIConfig[]
}

export interface StoreConfigurationProps {
  history?: any
  location?: any
}
const StoreConfiguration: React.FC<StoreConfigurationProps> = ({ history, location }) => {
  const { configurations } = useTypedSelector(state => ({
    configurations: state.configurations.configurations,
  }))

  const [selectedStore, setSelectedStore] = useState<StoreDto | undefined>(undefined)
  const [selectedCaptureMethod, setSelectedCaptureMethod] = useState<CaptureMethodConfig | undefined>(undefined)
  const [allowedCaptureMethods, setAllowedCaptureMethods] = useState<CaptureMethodUIConfig[] | undefined>(undefined)

  const dispatch = useDispatch()

  useEffect(() => {
    const localStorageStore = bffParceirosApiService.coordinator.getStore()

    if (!localStorageStore) toaster.showWarningToast(storeIsNotSetUp)

    const handleCaptureMethod = async () => {
      const { allowedCaptureMethods, savedCaptureMethod } = await getCaptureMethodConfigs(localStorageStore?.id)

      setSelectedCaptureMethod(savedCaptureMethod.captureMethod)
      setAllowedCaptureMethods(allowedCaptureMethods)
    }

    handleCaptureMethod()
  }, [])

  useEffect(() => {
    if (selectedStore) {
      const localStorageOperator = LocalStorageWrapper.getItem(LocalStorageItem.OPERATOR)
      return OperatorCenterHelper.redirectToOperatorHome(configurations, localStorageOperator.role)
    }
  }, [configurations])

  const getCaptureMethodConfigs = async (storeId?: string) => {
    // Need to fetch the storey by id so we can access the settings property
    const storeWithSettings = storeId ? await bffParceirosApiService.retailers.fetchStoreById(storeId) : undefined
    const loggedOperator = bffParceirosApiService.auth.getUser()
    const savedCaptureMethod = CaptureMethodService.get()
    const allowedCaptureMethods = CaptureMethodService.allowedCaptureMethods(loggedOperator?.role as Role, storeWithSettings?.settings?.captureMethod)

    return { allowedCaptureMethods, savedCaptureMethod }
  }

  const onCaptureMethodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const captureMethod = e.currentTarget.value as CaptureMethodConfig
    CaptureMethodService.set({ captureMethod })
    setSelectedCaptureMethod(captureMethod)
  }

  const handleConfirmStore = async () => {
    if (selectedStore) {
      dispatch(ConfigurationsSliceReducer.actions.fetchConfigurations({}))

      toaster.showSuccessToast('Loja configurada com sucesso!')
    }
  }

  const shouldRenderCaptureMethodConfig = !!allowedCaptureMethods?.length

  return (
    <div style={formContainerStyle}>
      <div>
        <Card style={formItemsContainerStyle} interactive={false} elevation={Elevation.TWO}>
          <CardTitle>Configuração de Loja</CardTitle>

          <StoreSelection handleSelectedStore={setSelectedStore} />

          <Button style={{ marginTop: '32px' }} onClick={handleConfirmStore} intent="primary">
            Confirmar Loja
          </Button>
        </Card>
      </div>

      {shouldRenderCaptureMethodConfig ? (
        <div style={{ marginTop: '2vh' }}>
          {!!!selectedCaptureMethod ? (
            <Loading />
          ) : (
            <Card style={formItemsContainerStyle} interactive={false} elevation={Elevation.TWO}>
              <CardTitle>Padrão de vendas para o usuário</CardTitle>
              <CardSubtitle>
                Esta configuração permite que você escolha se deseja fazer as vendas identificando o cliente via CPF ou utilizando captura via QR Code.
                <br />
                Você pode alterar essa configuração a qualquer momento.
              </CardSubtitle>
              <HTMLSelect value={selectedCaptureMethod} onChange={onCaptureMethodChange}>
                {allowedCaptureMethods?.map((captureMethod, idx) => (
                  <option value={captureMethod.captureMethod} key={idx}>
                    {captureMethod.label}
                  </option>
                ))}
              </HTMLSelect>
            </Card>
          )}
        </div>
      ) : (
        undefined
      )}
    </div>
  )
}

export default StoreConfiguration

const CardSubtitle = styled.p`
  text-align: left;
`

const CardTitle = styled.h3`
  align-self: flex-start;
  margin-bottom: 1.5em;
`

const formContainerStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100vh',
  marginTop: '5vh',
  // width: '100vw',
  flexDirection: 'column' as 'column',
}
