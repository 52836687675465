import * as React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ArrowBackButton } from '../../../atoms/Buttons/ArrowBackButton'
import { AlertDropdown } from '../../../atoms/Dropdown/AlertDropdown'
import { formatBRL } from '../../../cash-register/common/formatBRL'
import { ReactComponent as AlertIconSmall } from '../../../common/assets/images/alert-icon-small.svg'
import { DateFormat, formatDate, TimeZone } from '../../../common/FormatDate'
import SimulationList from '../../../molecules/ContractsSimulator/SimulationList'
import { ContractsSimulatorPages } from '../../../pages/ContractsSimulator/ContractsSimulator'
import ContractsSimulatorSliceReducer from '../../../redux/reducers/contracts-simulator/contracts-simulator.reducer'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import analyticsService from '../../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../../services/analytics/events'
import { black, blue, gray, green } from '../../../ui/utils/_colors'

interface ContractsSimulatorResultProps {
  handleGoBack: () => void
}

export const ContractsSimulatorResult: React.FC<ContractsSimulatorResultProps> = ({ handleGoBack }) => {
  const { simulationValue, simulationResult, analyticsEventPrefix } = useTypedSelector(state => ({
    simulationValue: state.contractsSimulator.simulationValue,
    simulationResult: state.contractsSimulator.simulation,
    analyticsEventPrefix: state.contractsSimulator.analyticsEventPrefix,
  }))

  const dispatch = useDispatch()

  const handleEditSimulation = () => {
    analyticsService.sendEvent(analyticsEventPrefix + AnalyticsEvents.edit_simulation_click)
    dispatch(ContractsSimulatorSliceReducer.actions.setPage(ContractsSimulatorPages.Form))
  }

  const simulationExpirationDateFormatted = simulationResult?.expirationTimestamp ? formatDate(simulationResult.expirationTimestamp, DateFormat.DAY_MONTH_BR_DATE_FORMAT, TimeZone.MANAUS) : null

  const defineAlertText = () => {
    if (!!simulationResult?.storeName && simulationResult.expirationTimestamp) {
      return (
        <>
          Esta simulação é válida apenas para a Super Compra aprovada na loja <strong>{simulationResult.storeName}</strong> com expiração em <strong>{simulationExpirationDateFormatted}</strong>.
        </>
      )
    }

    if (!!simulationResult?.retailerName && simulationResult.expirationTimestamp) {
      return (
        <>
          Esta simulação é válida apenas para a Super Compra aprovada no varejo <strong>{simulationResult.retailerName}</strong> com expiração em <strong>{simulationExpirationDateFormatted}</strong>.
        </>
      )
    }

    return 'Esta simulação é válida apenas para sua loja e não considera cupons de desconto ou créditos extras disponíveis para o cliente.'
  }

  const alertText = defineAlertText()

  return (
    <MainContainer>
      <BackButtonContainer>
        <ArrowBackButton onBackButtonClick={handleGoBack} />
      </BackButtonContainer>

      <Title>Opções de parcelamento</Title>

      <LimitContainer>
        <BoldText>Valor simulado</BoldText>

        <RowView>
          <LimitValue>{formatBRL(simulationValue!)}</LimitValue>
          <EditButton onClick={handleEditSimulation}>Editar valor</EditButton>
        </RowView>
      </LimitContainer>

      <Line />

      <AlertDropdown backgroundColor={blue.alert50} icon={<AlertIconSmall style={{ marginTop: 1 }} />} dropdownVisibleText={'Entenda esta simulação'} dropdownHiddenText={alertText} />

      <SimulationContainer>
        <SimulationList simulation={simulationResult!.simulation} />
      </SimulationContainer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: auto;
  width: 100%;
  padding: 24px;
`

const BackButtonContainer = styled.div``

const Title = styled.text`
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  color: ${black.primary};
  text-align: left;
  line-height: 24px;
  margin-top: 16px;
`

const LimitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: 16px;
`

const BoldText = styled.text`
  font-family: Inter;
  font-size: 12px;
  color: ${gray.scale700};
  font-weight: 600;
  line-height: 16px;
  text-align: left;
`

const LimitValue = styled.text`
  font-family: Inter;
  font-size: 20px;
  color: ${black.primary};
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
`

const EditButton = styled.div`
  font-family: Inter;
  font-size: 14px;
  color: ${green.dark2};
  font-weight: 600;
  line-height: 20px;
  text-align: right;
  cursor: pointer;
`

const RowView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Line = styled.hr`
  color: ${gray.scale400};
  border-top: 2px solid ${gray.scale400};
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
`

const SimulationContainer = styled.div`
  margin-top: 16px;
`
