import React from 'react'
import styled from 'styled-components'
import { ReactComponent as InfotipIcon } from '../../common/assets/super-purchase/infotip.svg'
import { Tooltip } from '@material-ui/core'

interface InfoRowProps {
  color: string
  title: string
  value: string
  subtitle: string
  infotip: string
}

const InfoRow: React.FC<InfoRowProps> = ({ color, title, value, subtitle, infotip }) => {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <MainContainer>
      <CircleContainer>
        <Circle color={color} />
      </CircleContainer>

      <RowContainer>
        <ValueContainer>
          <Tooltip title={infotip} open={open} arrow={true} placement="top" onClose={() => handleTooltipClose()} leaveTouchDelay={6000} interactive>
            <TitleContainer onClick={() => handleTooltipOpen()}>
              <Title>{title}</Title>
              <CustomInfotipIcon />
            </TitleContainer>
          </Tooltip>
          <Value>{value}</Value>
        </ValueContainer>

        <Subtitle>{subtitle}</Subtitle>
      </RowContainer>
    </MainContainer>
  )
}

export default InfoRow

interface InfoRowStyleProps {
  color: string
}

const CustomInfotipIcon = styled(InfotipIcon)`
  align-self: center;
  margin-left: 8px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 16px;
`

const RowContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: space-between;
`

const CircleContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const Circle = styled.div<InfoRowStyleProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props: InfoRowStyleProps) => props.color};
  margin-right: 16px;
`

const ValueContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #4d4d4d;
`

const Value = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #4d4d4d;
  margin-left: auto;
`

const Subtitle = styled.div`
  font-size: 12px;
  color: #a0a0a0;
  margin-top: 4px;
`
