import { Box } from 'grommet'
import * as React from 'react'
import { HistoryProps } from '../../../../App'
import { getLogoDarkBackgroundUrl } from '../../../../common/AssetsHelper'
import { Anchor, Heading, SideBar } from '../../../../legacy-lib/design-kit-ui'
import { Close } from '../../../../ui/Icons'
import { LogoImg } from './style'

export interface ISideBarMenuProps extends HistoryProps {
  topNavItems: React.ReactNode
  bottomNavItems: React.ReactNode
  onCloseSideBar: () => void
}

export interface ISideBarMenuState {}

export default class SideBarMenu extends React.Component<ISideBarMenuProps, ISideBarMenuState> {
  constructor(props: ISideBarMenuProps) {
    super(props)

    this.state = {}
  }

  public render() {
    return (
      <SideBar
        topNavItems={this.props.topNavItems}
        bottomNavItems={this.props.bottomNavItems}
        onEsc={this.props.onCloseSideBar}
        headIcons={[<Close key="close" color="white" onClick={this.props.onCloseSideBar} />]}
        logo={
          <Anchor href="/">
            <LogoImg src={getLogoDarkBackgroundUrl()} />
          </Anchor>
        }
      >
        <Box margin={{ right: 'small' }}>
          <Heading color="white" size="30px">
            Olá, como posso te ajudar?
          </Heading>
        </Box>
      </SideBar>
    )
  }
}
