import styled from 'styled-components'
import { Button, Heading } from '../../../../legacy-lib/design-kit-ui'

export const LogoImg = styled.img`
  alt: logo;
  height: 48px;
`
export const LoginHeading = styled(Heading)`
  margin-top: 0;
  margin-bottom: 6px;
`
export const ButtonBox = styled(Button)`
  margin-top: 30px;
  width: 95%;
`
