export enum AnalyticsEvents {
    budget_button = 'budget_button',
    cashier_purchase_button = 'cashier_purchase_button',
    cashier_payment_button = 'cashier_payment_button',
    qr_code_payment_creation = 'qr_code_payment_creation',
    qr_code_payment_invalid_value = 'qr_code_payment_invalid_value',
    qr_code_payment_go_cashier = 'qr_code_payment_go_cashier',
    qr_code_payment_go_input = 'qr_code_payment_go_input',
    qr_code_payment_go_legacy = 'qr_code_payment_go_legacy',
    qr_code_payment_zoom = 'qr_code_payment_zoom',
    qr_code_payment_print_qr_code = 'qr_code_payment_print_qr_code',
    qr_code_payment_success = 'qr_code_payment_success',
    qr_code_payment_print_receipt = 'qr_code_payment_print_receipt',
    purchase_borrower_document = 'purchase_borrower_document',
    purchase_proposal = 'purchase_proposal',
    purchase_proposal_details_dropdown = 'purchase_proposal_details_dropdown',
    purchase_send_borrower_limit = 'purchase_send_borrower_limit',
    purchase_selected_installments = 'purchase_selected_installments',
    purchase_otp_verification = 'purchase_otp_verification',
    purchase_success = 'purchase_success',
    purchase_print_receipt = 'purchase_print_receipt',

    sp_approved_start_application = 'sp_approved_start_application',
    sp_show_installments_options = 'sp_show_installments_options',
    sp_hide_installments_options = 'sp_hide_installments_options',
    sp_approved = 'sp_approved',
    sp_approved_application_approved = 'sp_approved_application_approved',

    sp_denied = 'sp_denied',

    sp_denied_start_application = 'sp_denied_start_application',
    sp_denied_start_simulation = 'sp_denied_start_simulation',
    sp_denied_application_approved = 'sp_denied_application_approved',
    sp_denied_ume_limit_above_10 = 'sp_denied_ume_limit_above_10',
    sp_denied_ume_limit_below_10 = 'sp_denied_ume_limit_below_10',

    sp_approved_application_success = 'sp_approved_application_success',
    sp_approved_application_start_simulation = 'sp_approved_application_start_simulation',
    sp_denied_application_success = 'sp_denied_application_success',
    sp_denied_application_start_simulation = 'sp_denied_application_start_simulation',

    start_simulation = 'start_simulation',
    simulation_summary = 'simulation_summary',
    simulation_go_back = 'simulation_go_back',
    edit_simulation_click = 'edit_simulation_click',
    edit_simulation_screen = 'edit_simulation_screen',
    start_edited_simulation = 'start_edited_simulation',
    close_simulation = 'close_simulation',
    simulation_invalid_values = 'simulation_invalid_values',
    simulation_error = 'simulation_error',

    info_expanded = 'info_expanded',
    info_collapsed = 'info_collapsed',

    christmas_banner = 'christmas_banner'
}